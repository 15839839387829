import { Pipe, PipeTransform } from '@angular/core';
import {ServiceData} from "../services/services.service";
import {UserCategoryData} from "../services/user-categories-data.service";
import {ProjectData} from "../services/projects.service";

@Pipe({
    name: 'userProjectsForSelect'
})
export class UserProjectsForSelectPipe implements PipeTransform {

    transform(value: ProjectData[], ...args: unknown[]): { [key: string]: string }
    {
        let result = { };
        if (value === null) return result;
        const filtered = value.filter(v => v.user);
        const sorted = filtered.sort((item1, item2) => {
           if (item1.user.name > item2.user.name) return 1;
           return 0;
        });

        for (const ud of sorted) {
            result[ud.projects_id] = (ud.project_name) ? ud.user.name + " - " + ud.project_name : ud.user.name;
        }

        return result;
    }

}
