import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ApiEndpointsService } from "./api-endpoints.service";
import { HttpClient } from '@angular/common/http';
import {QuarterHelperService} from "./quarter-helper.service";

export interface FacilityInfo
{
    facilities_id: string;
    shortcut: string;
    fullname: string;
    crm_id: string;
    cfhead_mail: string;
    dt_created: string,
    dt_modified: string,
    fee_calc_caption: string,
    fee_calc_default_expr: string,
    acknowledgement: string
}

@Injectable({
    providedIn: 'root'
})
export class QuarterService
{
    constructor(
        private endpoints: ApiEndpointsService,
        private httpClient: HttpClient,
        private qt_helper: QuarterHelperService
    )
    {
        this.facility_list$.subscribe((val) => this.facility_list = val);

        this.selected_quarter$.subscribe((val) => this.selected_quarter = val);
        this.quarter_list$.subscribe((val) => this.quarter_list = val);
    }

    /**
     * Quarter that is currently selected
     */
    selected_quarter$: Subject<string> = new BehaviorSubject<string>("");
    selected_quarter: string = "";

    quarter_list$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    quarter_list: string[] = [ ];

    /**
     * Facilities for selected quarter
     */
    facility_list$: BehaviorSubject<FacilityInfo[]> = new BehaviorSubject<FacilityInfo[]>([]);
    facility_list: FacilityInfo[] = [ ];
    selected_facility: string;

    loadQuarterList()
    {
        let o = this.httpClient.get<string[]>(this.endpoints.QUARTERS_URL);
        o.subscribe(v => this.quarter_list$.next(v));
    }

    loadFacilityList()
    {
        let o = this.httpClient.get<FacilityInfo[]>(this.endpoints.FACILITIES_URL(this.selected_quarter));
        o.subscribe((v) => { console.log("FACLIST"); console.log(v); this.facility_list$.next(v); this.facility_list = v });
    }

    selectQuarter(quarter: string)
    {
        this.selected_quarter$.next(quarter);
        this.loadFacilityList();
    }

    select_facility(facility_id: string)
    {
        console.log("Selecting facility: " + facility_id);
        this.selected_facility = facility_id;
    }

    selected_is_current() : boolean
    {
        return (this.selected_quarter === this.qt_helper.currentQuarter());
    }

    /**
     * Selects currently selected quarter, notifiyng the listeners.
     */
    refresh_quarter()
    {
        this.selectQuarter(this.selected_quarter.toString());
    }
}
