<div class="container-fluid">
    <div class="d-flex">
        <div class="flex-grow-1">
            <app-toolbox (on_operation_complete)="refresh_quarter()" (on_utilimport_complete)="refresh_utilimport_result($event)"></app-toolbox>
        </div>

        <div style="flex-basis: 20rem;" class="mx-2 flex-shrink-0 justify-content-center">
            <app-stats #stats_component></app-stats>

            <a routerLink="/overviews" class="btn btn-ceitec mt-3">Multi-quarter overview</a>

            <div *ngIf="util_import_result" class="util-import-results">
                <ul>
                    <li>Excel sheets found: {{util_import_result.sheetnames.join(", ")}}</li>
                    <li>Total rows found: {{util_import_result.total_rows}}</li>
                    <li>Total rows imported: {{util_import_result.total_rows_imported}}</li>
                    <li>Unknown users (newly created): {{util_import_result.not_found_users_created.join(", ")}}</li>
                    <li>Unknown categories: {{util_import_result.not_mapped_categories.join(", ")}}</li>
                    <li>Unknown services: {{util_import_result.not_mapped_services.join(", ")}}</li>
                </ul>

                <ul>
                    Logs:
                    <li *ngFor="let log of util_import_result.logs">
                        {{log}}
                    </li>
                </ul>
            </div>
        </div>


    </div>

</div>
