import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ApiEndpointsService} from "./api-endpoints.service";
import {QuarterService} from "./quarter.service";
import {CurrentSync} from "../utils/current_sync";


export enum FacilityReportStatus {
    NotSentYet = "Not sent yet",
    Sent = "Sent",
    Approved = "Approved",
    Refused = "Refused"
}

export interface FacilityReportData {
    facility_reports_id: string;

    status: FacilityReportStatus;
    dt_last_reminder: Date;
    dt_status_changed: Date;
    cfhead_comments: string;
    admin_comments: string;
}

@Injectable({
    providedIn: 'root'
})
export class FacilityReportsService extends CurrentSync<FacilityReportData[]> {
    facility_reports$: Observable<FacilityReportData[]>;

    constructor(
        private httpc: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService
    ) {
        super();
        this.facility_reports$ = this.current$;
    }

    load_reports(quarter: string = this.qt.selected_quarter) {
        this.httpc.get<FacilityReportData[]>(this.urls.FACILITY_REPORTS_URL(quarter))
            .subscribe(data => this.next_current(data));
    }

    get_report_for_fac(facility: string, quarter: string = this.qt.selected_quarter) {
        console.log("get for fac")
        return this.httpc.get<FacilityReportData>(this.urls.FACILITY_REPORT_INFO_URL(facility, quarter));
    }

    send_report(report: FacilityReportData, quarter: string = this.qt.selected_quarter) {
        return this.httpc.post(this.urls.FACILITY_REPORT_SEND_URL(quarter, report.facility_reports_id), {}, {responseType: "text"});
    }
}
