<div class="container-fluid">
    <div class="d-flex flex-nowrap" *ngIf="user_reports$ | async as user_reports">
        <div class="flex-grow-1 pt-2" *ngIf="selection_map$ | async as selection_map">
            <!-- Table of reports and their statuses -->
            <div *ngIf="show_actions_panel$ | async" class="d-flex align-items-center mb-1">
                <div>Collective action:</div>
                <div class="flex-grow-1"></div>
                <div *ngIf="processing_status$ | async as ps else pselse">
                    {{ ps }}
                </div>

                <ng-template #pselse>
                    <ng-container *ngIf="selection_reports_to_send$ | async as selection_reports_to_send">
                        <button class="btn btn-ceitec btn-sm mx-1" (click)="send_multiple_click(selection_reports_to_send.length)"
                                *ngIf="selection_reports_to_send.length">Send ({{ selection_reports_to_send.length }})</button>
                    </ng-container>

                    <ng-container *ngIf="selection_reports_to_invoice_or_later$ | async as selection_reports_to_inv">
                        <button class="btn btn-ceitec btn-sm mx-1" (click)="invoice_multiple_click(selection_reports_to_inv.length)"
                                *ngIf="selection_reports_to_inv.length">Issue invoice ({{ selection_reports_to_inv.length }})</button>
                    </ng-container>

                    <ng-container *ngIf="selection_reports_to_resolve$ | async as selection_reports_to_res">
                        <button class="btn btn-ceitec btn-sm mx-1" (click)="resolve_click(selection_reports_to_res.length)"
                                *ngIf="selection_reports_to_res.length">Resolve ({{ selection_reports_to_res.length }})</button>
                    </ng-container>

                    <ng-container *ngIf="selection_reports_to_crmgen$ | async as selection_reports_to_crmgen">
                        <button class="btn btn-ceitec btn-sm mx-1" (click)="crmgen_multiple_click(selection_reports_to_crmgen.length)"
                                *ngIf="selection_reports_to_crmgen.length">Gen. CRM order ({{ selection_reports_to_crmgen.length }})</button>
                    </ng-container>
                </ng-template>
            </div>
            <table class="table-blue-interactive w-100" [class.disableall]="processing_status$ | async">
                <thead>

                    <tr>
                        <th><input type="checkbox" class="select-all-checkbox" (change)="change_all_selection(selection_map, user_reports, $event.target.checked)" /></th>
                        <th>User</th>
                        <th>Status</th>
                        <th>Status changed</th>
                        <th>Action</th>
                    </tr>

                </thead>

                <tbody>
                    <tr *ngFor="let r of user_reports; trackBy: identify_report"
                        [class]="get_report_classes(r)"
                        routerLinkActive="active" [routerLink]="['/', qt_service.selected_quarter, 'user-reporting', r.user_reports_id]">
                        <td><input type="checkbox" (click)="$event.stopPropagation()" [checked]="is_selected(selection_map, r)" (change)="change_selection(selection_map, $event.target.checked, r)" /></td>
                        <td>{{ r.user.name }}</td>
                        <td>{{ r.status }}</td>
                        <td>{{ r.dt_status_changed | date }}</td>
                        <td>
                            <div class="d-flex align-content-center button-strip" style="white-space: nowrap">
                                <button *ngIf="is_sendable(r)" class="btn btn-ceitec-light btn-sm align-self-center" (click)="send_report(r)">Send</button>
                                <button *ngIf="is_invoicable(r)" (click)="invoice_now(r)" class="btn btn-ceitec-light btn-sm align-self-center">Issue invoice</button>
                                <button *ngIf="is_resolvable(r)" (click)="resolve(r)" class="btn btn-ceitec-light btn-sm align-self-center">Resolved</button>
                                <button *ngIf="is_crm_order_generatable(r)" (click)="crm_generate_order(r)" class="btn btn-ceitec-light btn-sm align-self-center">Gen. CRM order</button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <hr/>


        </div>

        <div *ngIf="active_report$ | async as active_report" class="align-self-start top-0 position-sticky">
            <div *ngIf="active_report.user_comments" class="report-user-commentary">
                <strong>User commentary: </strong>
                <p style="white-space: pre-line">{{ active_report.user_comments }}</p>
            </div>
            <div class="report-preview-container">
                <iframe [cachedSrc]="get_iframe_url(active_report)"></iframe>
            </div>
        </div>
    </div>
</div>
