import { Pipe, PipeTransform } from '@angular/core';
import {ServiceData} from "../services/services.service";
import {UserCategoryData} from "../services/user-categories-data.service";

@Pipe({
    name: 'userCategsForSelect'
})
export class UserCategsForSelectPipe implements PipeTransform {

    transform(value: UserCategoryData[], ...args: unknown[]): { [key: string]: string }
    {
        let result = { };
        if (value === null) return result;

        for (const ud of value) {
            result[ud.user_categories_id] = ud.name;
        }

        return result;
    }

}
