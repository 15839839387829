import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor
{
    constructor(@Inject("BASE_URL") private base_url: string) 
    { 
        this.base_url = base_url.trim();
        this.base_url = this.base_url.replace(/\/+$/g, '');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        let new_url = req.url.replace(/^\/+/g, ''); // Trim slashes
        req = req.clone({
            url: this.base_url + "/" + new_url
        });

        return next.handle(req);
    }
}