import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {FacilityInfo, QuarterService} from '../../services/quarter.service';
import {QuarterHelperService} from '../../services/quarter-helper.service';
import {MessagesService} from '../../utils/messages/messages.service';
import {ApiEndpointsService} from "../../services/api-endpoints.service";

export interface UtilImportResultData
{
    not_mapped_services: string[];
    not_mapped_categories: string[];
    not_found_users_created: string[];
    sheetnames: string[];
    total_rows: number;
    total_rows_imported: number;
    logs: string[];
}

@Component({
    selector: 'app-toolbox',
    templateUrl: './toolbox.component.html',
    styleUrls: ['./toolbox.component.scss']
})
export class ToolboxComponent implements OnInit {
    @Output() on_operation_complete: EventEmitter<void> = new EventEmitter();
    @Output() on_utilimport_complete: EventEmitter<UtilImportResultData> = new EventEmitter<UtilImportResultData>();

    constructor(
        private httpc: HttpClient,
        private qt: QuarterService,
        private messages: MessagesService,
        private qt_helper: QuarterHelperService,
        private router: Router,
        private urls: ApiEndpointsService
    ) {
    }

    ngOnInit(): void {
    }

    on_initialize_quarter() {
        this.httpc.get(`dbman/init_quarter/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => this.on_success(m));
    }

    on_purge_quarter() {
        this.httpc.get(`dbman/purge_quarter/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => this.on_success(m));
    }

    on_import_quarter() {
        let previous_quarter = this.qt_helper.previous_quarter(this.qt.selected_quarter);
        this.httpc.get(`dbman/transfer_quarter/${previous_quarter}/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => this.on_success(m));

    }

    on_purge_utilization() {
        this.httpc.get(`dbman/purge_quarter_utilization/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => this.on_success(m));
    }

    on_import_excel(files: FileList) {
        if (files.length < 1) {
            this.messages.error("No .xlsx file selected for importing.");
            return;
        }

        let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            formData.append("excel_files[]", file, file.name);
        }

        let lasturl = this.router.url;
        this.httpc.post<UtilImportResultData>(`dbman/import_excel/${this.qt.selected_quarter}`, formData)
            .subscribe(m => {
                this.on_utilimport_complete.emit(m);
            });
    }

    /**
     * Write success message and refresh the facility list.
     * @param message
     */
    on_success(message: string) {
        this.messages.success(message);
        this.on_operation_complete.emit();
    }

    on_import_services() {
        let lasturl = this.router.url;
        this.httpc.get(`dbman/import_crm_pricelists/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => {
                this.on_success(m);
                this.router.navigateByUrl(lasturl);
            });
        this.router.navigateByUrl("/crm_wait");
    }

    on_db_backup() {
        this.httpc.get(`dbman/backup`, {responseType: "text"})
            .subscribe(m => this.on_success(m));
    }

    async create_cf(cf_name: string) {
        console.log(cf_name)
        let creationData: Partial<FacilityInfo> = {
            facilities_id: cf_name,
            shortcut: "<unspecified>",
            fullname: "<unspecified>",
            cfhead_mail: ""
        }
        await this.httpc.post(this.urls.FACILITIES_URL(this.qt.selected_quarter),
            creationData)
            .toPromise()

        this.qt.loadFacilityList()
        await this.router.navigateByUrl(`${this.qt.selected_quarter}/facility/${cf_name}/cf-setup`)
    }

    on_purge_services() {
        this.httpc.get(`dbman/purge_services/${this.qt.selected_quarter}`, {responseType: "text"})
            .subscribe(m => this.on_success(m));
    }
}
