import {Observable} from "rxjs";

export type DataValue = string | number | Date;
export type EntityTableData = { [key: string]: DataValue };
export enum InputType
{
    TEXT, TEXTAREA, SELECT, NUMBER
}

export interface EntityTableColumnMetadata
{
    property_key: string;
    display_name: string;
    transform?: (val: DataValue) => DataValue;
    value_map?: { [key: string]: DataValue };
    value_map$?: Observable<{ [key: string]: DataValue }>;

    /**
     * Whether this property should be rendered as editable input (text, select...)
     */
    editable?: boolean;
    /**
     * Whether property should be rendered as select input - data for select are taken from value_map property
     */
    select?: boolean;

    input_type?: InputType;
}

/**
 * Controls behavior of the entity table renderer
 */
export interface EntityTableMetadata
{
    table_caption: string;
    render_columns: EntityTableColumnMetadata[];
    api_url?: string;
    id_property_name: string;
}

