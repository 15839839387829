import { Input, Directive, ElementRef, Renderer2 } from "@angular/core";

// Because of this:
// https://stackoverflow.com/questions/48306443/stop-angular-reloading-iframes-when-changing-components/54250452

@Directive({
    selector: 'iframe'
  })
export class CachedSrcDirective 
{
    @Input() 
    public get cachedSrc(): string 
    {
        return this.elRef.nativeElement.src;
    }

    public set cachedSrc(src: string) 
    {
        if (this.elRef.nativeElement.src !== src) {
            this.renderer.setAttribute(this.elRef.nativeElement, 'src', src);
        }
    }

    constructor(
        private elRef: ElementRef,
        private renderer : Renderer2
    ) { }
}