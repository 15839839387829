//
// @Component({
//   selector: 'app-cf-setup',
//   templateUrl: './cf-setup.component.html',
//   styleUrls: ['./cf-setup.component.scss']
// })
// export class CfSetupComponent implements OnInit {
//
//   constructor() { }
//
//   ngOnInit(): void {
//   }
//
// }
import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {FacilityInfo, QuarterService} from "../../services/quarter.service";
import {UserCategoryData} from "../../services/user-categories-data.service";
import {HttpClient} from "@angular/common/http";
import {ApiEndpointsService} from "../../services/api-endpoints.service";
import {filter, map} from "rxjs/operators";
import {Router} from "@angular/router";
import {MessagesService} from "../../utils/messages/messages.service";

export interface FacilityUserCategoryData
{
    facility_user_categories_id: string;

    discount_expression: string;
    overhead_costs_expression: string;
    profit_expression: string;
    contract_name: string;
    issue_invoice_template: string;

    fk_user_categories_id: string;
    fk_facilities_id: string;
}

@Component({
   selector: 'app-cf-setup',
   templateUrl: './cf-setup.component.html',
   styleUrls: ['./cf-setup.component.scss']
})
export class CfSetupComponent implements OnInit {

    core_facility$: Observable<FacilityInfo>;
    user_categories$: Observable<UserCategoryData[]>;
    facility_user_categories$:Observable<FacilityUserCategoryData[]>

    private facility_user_categories_behav$: BehaviorSubject<FacilityUserCategoryData[]>;
    private user_categories_behav$: BehaviorSubject<UserCategoryData[]>;

    constructor(
        private httpc: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService,
        private router: Router,
        private messages: MessagesService
    ) { }

    ngOnInit(): void {
        let cfreq = this.urls.FACILITY_URL(this.qt.selected_quarter, this.qt.selected_facility);
        console.log(cfreq)
        this.core_facility$ = this.httpc.get<FacilityInfo>(cfreq);

        this.user_categories_behav$ = new BehaviorSubject<UserCategoryData[]>([]);
        this.user_categories$ = this.user_categories_behav$;

        this.facility_user_categories_behav$ = new BehaviorSubject<FacilityUserCategoryData[]>([]);
        this.facility_user_categories$ = this.facility_user_categories_behav$;

        this.httpc.get<FacilityUserCategoryData[]>(this.urls.FACILITIES_USER_CATEGS_URL(this.qt.selected_quarter, this.qt.selected_facility))
            .subscribe(this.facility_user_categories_behav$);

        this.httpc.get<UserCategoryData[]>(this.urls.USER_CATEGORIES_URL(this.qt.selected_quarter))
            .subscribe(this.user_categories_behav$);

    }

    categs_for_facility$(facilities_id: string): Observable<FacilityUserCategoryData[]> {
        return this.facility_user_categories_behav$.pipe(
            map(val => {
                let result = val.filter(v => v.fk_facilities_id == facilities_id);
                console.log("mapping" , val, result)
                return result;
            } ),

        )

    }
    debg(cf: FacilityInfo) {
        console.log(cf)
        return cf;
    }

    categs_for_facility(facilities_id: string): FacilityUserCategoryData[]  {
         return this.facility_user_categories_behav$.getValue().filter(v => v.fk_facilities_id == facilities_id);
    }

    categ_name_by_id(id: string) {
        let categ = this.user_categories_behav$.getValue().filter(v => v.user_categories_id == id)[0]
        if (categ) return categ.name;
    }

    get_cf_patch_url() {
        return this.urls.FACILITY_URL(this.qt.selected_quarter, this.qt.selected_facility);
    }

    get_categ_patch_url(uc: FacilityUserCategoryData) {
        return this.urls.FACILITIES_USER_CATEG_URL(this.qt.selected_quarter, uc.facility_user_categories_id);
    }

    async drop_cf() {
        await this.httpc.delete(this.urls.FACILITY_URL(this.qt.selected_quarter, this.qt.selected_facility)).toPromise();
        this.qt.loadFacilityList();
        await this.router.navigateByUrl("/");
    }
}

