import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from './api-endpoints.service';
import { UserData } from "./user-data.service";
import {FacilityInfo, QuarterService} from './quarter.service';
import { CurrentSync } from '../utils/current_sync';
import {PaymentMethod, UserReportData} from "./user-reporting.service";

export interface ProjectData
{
    projects_id: string;

    project_name: string;
    project_description: string;
    funds: number;
    funds_plus: number;

    payment_method: PaymentMethod;
    payment_sources: string;

    fk_users_id: string;
    fk_facilities_id: string;
    user: UserData;
    facility: FacilityInfo;

    service_usages_count: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectsService extends CurrentSync<ProjectData[]>
{

  constructor(
    private httpc: HttpClient,
    private urls: ApiEndpointsService,
    private qt: QuarterService
  ) { super([]) }

  load_projects(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter)
  {
    this.httpc.get<ProjectData[]>(this.urls.PROJECTS_FOR_FACILITY_URL(quarter, facility))
        .subscribe(data => this.next_current(data));
  }

  new_project(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter): Observable<ProjectData>
  {
    return this.httpc.post<ProjectData>(this.urls.PROJECTS_URL(quarter), { fk_facilities_id: facility });
  }

  get_patch_url(id: string, quarter: string = this.qt.selected_quarter)
  {
    return this.urls.PROJECT_URL(quarter, id);
  }

  delete_project(id: string, quarter: string = this.qt.selected_quarter) {
      return this.httpc.delete(this.urls.PROJECT_URL(quarter, id), { responseType: "text" });
  }



}
