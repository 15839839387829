import { Injectable } from '@angular/core';
import { Message, MessageType } from './message';
import { Subject } from 'rxjs';

@Injectable()
export class MessagesService 
{    
    private MAX_HISTORY_COUNT = 100;
    private messages: Message[] = [ ];
    public message_stream: Subject<Message> = new Subject();

    constructor() { }

    add(message: Message)
    {
        this.messages.unshift(message);
        if (this.messages.length > this.MAX_HISTORY_COUNT)
        {
            this.messages.pop();
        }
        
        this.message_stream.next(message);
    }

    // Shortcuts for creating messages:
    error(text: string)
    {
        this.add(new Message(MessageType.ERROR, text));
    }

    info(text: string)
    {
        this.add(new Message(MessageType.INFO, text));
    }
    
    success(text: string)
    {
        this.add(new Message(MessageType.SUCCESS, text));
    }

    warn(text: string)
    {
        this.add(new Message(MessageType.WARNING, text));
    }
}
