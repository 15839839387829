<!--<label><input type="checkbox" [(ngModel)]="push_changes_crm" name="push-changes"/> Push changes to CRM</label>-->

<table class="table table-bordered table-sm tbl-realtime" *ngIf="pricelists_data$ | async as pricelist">

    <thead class="thead-dark">

    <tr class="width-row">
        <th></th><th></th><th></th>
        <th *ngFor="let pl of pricelist"></th>
        <th></th>
    </tr>

    <tr class="caption-row">
        <th [colSpan]="pricelist.length + 4">
            <div class="d-flex">
                <div class="px-2 w-100">Services</div>
                <div class="ml-auto px-2 cursor-pointer" (click)="spawn_row()">
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </th>
    </tr>

    <tr class="thead-row">
        <th>Name</th>
        <th>Unit</th>
        <th>CRM code</th>

        <th *ngFor="let c of pricelists_data$ | async">
            {{ c.name }}
        </th>
        <th></th>
    </tr>

    </thead>

    <tbody>
    <tr *ngFor="let s of services$ | async; trackBy: identify_service">
            <!-- Service name -->
        <td>
            <util-realtime-textinput [property_key]="'name'" [patch_url]="patch_service_url(s)"
                                     [current_value]="s.name"></util-realtime-textinput>
        </td>
        <td>
            <util-realtime-textinput [property_key]="'unit_name'" [patch_url]="patch_service_url(s)"
                                     [current_value]="s.unit_name"></util-realtime-textinput>
        </td>
        <td>
            <util-realtime-textinput [property_key]="'crm_product_number'" [patch_url]="patch_service_url(s)"
                                     [current_value]="s.crm_product_number"></util-realtime-textinput>
        </td>

        <td *ngFor="let c of pricelist; trackBy: identify_pricelist">
            <div *ngIf="find_pricing(s, c) as pric">
                <util-realtime-numberinput [property_key]="'cost_per_unit'" [patch_url]="patch_pricing_url(pric)" [current_value]="pric.cost_per_unit" [decimals]="0"></util-realtime-numberinput>
            </div>
        </td>

        <td>
            <div (click)="delete_service(s)" class="d-flex align-items-center justify-content-center cursor-pointer">
                <i class="fas fa-trash"></i>
            </div>
        </td>
    </tr>
    </tbody>


</table>
