import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ApiEndpointsService {
    QUARTERS_URL = 'quarters';
    FACILITIES_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/facilities`;
    FACILITY_URL = (quarter: string, facility: string) => `${this.FACILITIES_URL(quarter)}/${facility}`;
    FACILITIES_USER_CATEGS_URL = (quarter: string, facility: string) => `${this.FACILITY_URL(quarter, facility)}/user_categs`;
    FACILITIES_USER_CATEG_URL = (quarter: string, id: string) => `${this.QUARTERS_URL}/${quarter}/facilities_user_categs/${id}`;
    USERS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/users`;
    USERS_BY_CAGETORIES_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/users/by_categories`;
    USER_MERGE_URL = (quarter: string, id_user_from: string, id_user_to: string) => `${this.USERS_URL(quarter)}/${id_user_from}/merge/${id_user_to}`;

    USER_CATEGORIES_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/user_categories`;
    QT_STATISTICS = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/stats`;
    USER_REPORTS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/user_reports`;
    USER_REPORT_PREVIEW = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/admin_view`;
    FACILITY_REPORTS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/facility_reports`;


    PROJECTS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/projects`;
    PROJECT_URL = (quarter: string, id: string) => `${this.PROJECTS_URL(quarter)}/${id}`;
    PROJECTS_FOR_FACILITY_URL = (quarter: string, facility_id: string) => `${this.PROJECTS_URL(quarter)}/for_facility/${facility_id}`;

    SERVICES_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/services`;
    SERVICES_FOR_FAC_URL = (quarter: string, facility: string) => `${this.SERVICES_URL(quarter)}/for_facility/${facility}`;
    SERVICE_URL = (quarter: string, id: string) => `${this.SERVICES_URL(quarter)}/${id}`;

    PRICINGS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/services/prices`;
    PRICINGS_URL_FOR_FAC = (quarter: string, facility_id: string) => `${this.PRICINGS_URL(quarter)}/for_facility/${facility_id}`;
    PRICING_URL = (quarter: string, id: string) => `${this.PRICINGS_URL(quarter)}/${id}`;

    PRICELISTS_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/pricelists`;

    SERVICE_PRICING_TABLE_URL = (quarter: string, facility_id: string) => `${this.SERVICES_URL(quarter)}/table${(facility_id) ? `/${facility_id}` : ''}`;

    SERVICE_USAGES_URL = (quarter: string) => `${this.QUARTERS_URL}/${quarter}/service_usages`;
    SERVICE_USAGE_URL = (quarter: string, id: string) => `${this.SERVICE_USAGES_URL(quarter)}/${id}`;
    SERVICE_USAGES_FOR_FACILITY = (quarter: string, facility_id: string) => `${this.SERVICE_USAGES_URL(quarter)}/for_facility/${facility_id}`;


    USER_REPORT_SEND_URL = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/admin_send`;
    USER_REPORT_INVOICE_LATER_URL = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/invoice_later`;
    USER_REPORT_INVOICE_NOW_URL = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/invoice_now`;
    USER_REPORT_GENERATE_ORDER_URL = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/generate_crm_order`;
    USER_REPORT_RESOLVE_URL = (quarter: string, reportid: string) => `${this.USER_REPORTS_URL(quarter)}/${reportid}/resolve`;

    FACILITY_REPORT_INFO_URL = (facility: string, quarter: string) => `${this.FACILITIES_URL(quarter)}/${facility}/report`;
    FACILITY_REPORT_SEND_URL = (quarter: string, reportid: string) => `${this.FACILITY_REPORTS_URL(quarter)}/${reportid}/admin_send`;
    FACILITY_REPORT_PREVIEW_URL = (selected_quarter: string, facility_reports_id: string) => `${this.FACILITY_REPORTS_URL(selected_quarter)}/${facility_reports_id}/preview`;

    constructor() {
    }


}


