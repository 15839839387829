import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: "quarter" })
export class QuarterPipe implements PipeTransform
{
    transform(value: string): String
    {
     return this.querter_format(value);
    }

    monthToQuarter(month: number = new Date().getMonth()): number
    {
        return Math.ceil( (month + 1) / 3 );
    }

    querter_format(quarter: string)
    {
        return `${quarter[quarter.length - 1]}Q ${quarter.substr(0, 4)}`;
    }
}
