import { Component, OnInit } from '@angular/core';
import {QuarterHelperService} from "../../services/quarter-helper.service";
import {QuarterService} from "../../services/quarter.service";
import {BehaviorSubject, Observable} from "rxjs";

@Component({
  selector: 'app-multiquarter-report',
  templateUrl: './multiquarter-report.component.html',
  styleUrls: ['./multiquarter-report.component.scss']
})
export class MultiquarterReportComponent implements OnInit {

    qt_list$: Observable<string[]>;

    qt_from$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    qt_to$: BehaviorSubject<string> = new BehaviorSubject("");

  constructor(private qt_helper: QuarterHelperService,private qts: QuarterService) {
    this.qt_from$.next(qt_helper.currentQuarter())
    this.qt_to$.next(qt_helper.currentQuarter())

  }

  ngOnInit(): void {
      this.qt_list$ = this.qts.quarter_list$;
      this.qts.loadQuarterList();

  }

    get_link(qtfrom: string, qt_to: string)
    {
        return `/api/facility-overviews/${qtfrom}/${qt_to}`;
    }
}
