export enum MessageType
{
    ERROR   = "message-error",
    SUCCESS = "message-success",
    WARNING = "message-warning",
    INFO    = "message-info"
}

export class Message
{
    public DELAY_BASE: number = 3000;

    constructor(
        public type: MessageType,
        public text: string,
        public forever: boolean = false
    ) { }

}
