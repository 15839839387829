import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { LocalStorageService } from '../utils/local-storage/local-storage.service';
import { Observable } from 'rxjs';
import { QuarterHelperService } from '../services/quarter-helper.service';

@Injectable({
    providedIn: 'root',
})
export class LatestQuarterGuard implements CanActivate
{
    LATEST_QT_KEY = "cf-accounting-latest-qt";

    constructor(
        private qhelper: QuarterHelperService,
        private loc_service: LocalStorageService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        let qtparam = next.paramMap.get("quarter");

        if (qtparam === "latest")
        {
            let qt = this.loc_service.retrieve<string>(this.LATEST_QT_KEY);
            if (qt === null)
            {
                qt = this.qhelper.currentQuarter();
            }

            return this.router.parseUrl(`/${qt}/overview`);
        }
        else
        {
            this.loc_service.save(this.LATEST_QT_KEY, qtparam);
            return true;
        }
    }
}
