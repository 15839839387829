import { Pipe, PipeTransform } from '@angular/core';
import {ServiceData} from "../services/services.service";

@Pipe({
    name: 'servicesForSelect'
})
export class ServicesForSelectPipe implements PipeTransform {

    transform(value: ServiceData[], ...args: unknown[]): { [key: string]: string }
    {
        let result = { };
        if (value === null) return result;

        for (const ud of value) {
            result[ud.services_id] = ud.name + " - " + ud.unit_name;
        }

        return result;
    }

}
