import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm-import-waiter',
  templateUrl: './crm-import-waiter.component.html',
  styleUrls: ['./crm-import-waiter.component.scss']
})
export class CrmImportWaiterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
