<table *ngIf="stats$ | async as stats">
    <thead>
        <tr style="border-bottom: 1px solid black">
            <th colspan="2" class="text-center"><strong>Statistics</strong></th>
        </tr>
    </thead>

    <tbody>
        <tr>
            <td>Total users</td>
            <td>{{ stats.total_users }}</td>
        </tr>

        <tr>
            <td>Total user categories</td>
            <td>{{ stats.total_user_categories }}</td>
        </tr>

        <tr [class.text-success]="stats.unresolved_user_reports == 0">
            <td>Unresolved user reports</td>
            <td>{{ stats.unresolved_user_reports }}</td>
        </tr>

        <tr [class.text-danger]="stats.pending_user_reports > 0"
            [class.text-success]="stats.pending_user_reports == 0"
        >
            <td>Pending user reports</td>
            <td>{{ stats.pending_user_reports }}</td>
        </tr>
        <tr><td colspan="2">&nbsp;</td></tr>
        <tr>
            <td>Total facilities</td>
            <td>{{ stats.total_facilities }}</td>
        </tr>

        <tr>
            <td>Total services</td>
            <td>{{ stats.total_services }}</td>
        </tr>

        <tr [class.text-success]="stats.unresolved_facility_reports == 0">
            <td>Unresolved facility reports</td>
            <td>{{ stats.unresolved_facility_reports }}</td>
        </tr>

        <tr [class.text-danger]="stats.pending_facility_reports > 0"
            [class.text-success]="stats.pending_facility_reports == 0"
        >
            <td>Pending facility reports</td>
            <td>{{ stats.pending_facility_reports }}</td>
        </tr>
    </tbody>
</table>
