import { Pipe, PipeTransform } from '@angular/core';
import { UserData } from '../services/user-data.service';

@Pipe({
  name: 'usersForSelect'
})
export class UsersForSelectPipe implements PipeTransform {

  transform(value: UserData[], ...args: unknown[]): { [key: string]: string }
  {
    let result = { };
    if (value === null) return result;

    for (const ud of value) {
      result[ud.users_id] = ud.name;
    }

    return result;
  }

}
