import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QuarterHelperService {
    
    constructor() { }

    currentQuarter(): string
    {
        let now  = new Date();
        let qnum = Math.ceil( (now.getMonth() + 1) / 3 );
        let year = now.getFullYear();
        return `${year}-${qnum}`;
    }

    is_quarter(qt: string): boolean
    {
        return (/\d{4}-(1|2|3|4)/.test(qt));
    }

    previous_quarter(qt: string = this.currentQuarter())
    {
        let qnum: number  = parseInt( qt[qt.length - 1] );
        let qyear: number = parseInt( qt.substr(0, 4) );

        if (qnum == 1) // First in yer year
        {
            return `${qyear - 1}-4`;
        }

        return `${qyear}-${qnum - 1}`;
    }
}
