import {Component, OnInit} from '@angular/core';
import {PricingData, ServiceData, ServicesService} from "../../services/services.service";
import {UserCategoriesDataService, UserCategoryData} from "../../services/user-categories-data.service";
import {Observable} from "rxjs";
import {QuarterService} from "../../services/quarter.service";
import {PricelistData, PricelistsDataService} from "../../services/pricelists-data.service";

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
    styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit
{
    services$: Observable<ServiceData[]>;
    pricelists_data$: Observable<PricelistData[]>;
    push_changes_crm: boolean = false;

    constructor(
        private services_service: ServicesService,
        private priclists_data_service: PricelistsDataService,
        private qt: QuarterService
    ) {
    }

    ngOnInit(): void {
        this.services$ = this.services_service.current$;
        this.pricelists_data$ = this.priclists_data_service.current$;

        this.priclists_data_service.fetch_all();
        this.services_service.load_services();

        this.push_changes_crm = this.qt.selected_is_current();
    }

    async spawn_row()
    {
        this.services_service.new_service().subscribe(result => {
           this.services_service.load_services();
        });

    }

    patch_service_url(s: ServiceData) { return this.services_service.get_service_patch_url(s.services_id); }
    patch_pricing_url(p: PricingData) { return this.services_service.get_pricing_patch_url(p.pricing_id); }
    find_pricing(service: ServiceData, pricelist: PricelistData)
    {
        return service.pricings.find(p => p.fk_pricelists_id == pricelist.pricelists_id);
    }

    identify_pricelist(index, item: PricelistData) { return item.pricelists_id; }
    identify_service(index, item: ServiceData) { return item.services_id; }

    delete_service(s: ServiceData) {
        this.services_service.delete_service(s.services_id)
            .subscribe(v => this.services_service.load_services());
    }
}
