
<table *ngIf="(core_facility$ | async) as cf"  class="tbl-cf-setup mb-3"  >
    <tr>
        <td>CF shortcut</td>
        <td><util-realtime-textinput [property_key]="'shortcut'" [current_value]="cf.shortcut" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>CF name</td>
        <td><util-realtime-textinput [property_key]="'fullname'" [current_value]="cf.fullname" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>CF head(s) contacts</td>
        <td><util-realtime-textinput [property_key]="'cfhead_mail'" [current_value]="cf.cfhead_mail" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>Fee calculation caption</td>
        <td><util-realtime-textinput [property_key]="'fee_calc_caption'" [current_value]="cf.fee_calc_caption" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>Fee calculation default expression</td>
        <td><util-realtime-textinput [property_key]="'fee_calc_default_expr'" [current_value]="cf.fee_calc_default_expr" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>CRM ID</td>
        <td><util-realtime-textinput [property_key]="'crm_id'" [current_value]="cf.crm_id" [patch_url]="get_cf_patch_url()"></util-realtime-textinput></td>
    </tr>

    <tr>
        <td>Acknowledgement</td>
        <td class="small">
            <util-realtime-textinput [property_key]="'acknowledgement'" [current_value]="cf.acknowledgement" [patch_url]="get_cf_patch_url()"></util-realtime-textinput>
        </td>
    </tr>





</table>

<table class="table table-bordered table-sm tbl-realtime">

    <thead class="thead-dark">
    <tr>
        <th></th>
        <th>Contract name</th>
        <th>Discount expression</th>
        <th>Profit expression</th>
        <th>Overhead costs expression</th>
        <th>Issue invoice template</th>
    </tr>
    </thead>

    <tbody>

        <tr *ngFor="let uc of facility_user_categories$ | async">

            <td> {{ categ_name_by_id(uc.fk_user_categories_id) }}&nbsp;</td>
            <td><util-realtime-textinput [current_value]="uc.contract_name" [property_key]="'contract_name'" [patch_url]="get_categ_patch_url(uc)"></util-realtime-textinput></td>
            <td><util-realtime-textinput [current_value]="uc.discount_expression" [property_key]="'discount_expression'" [patch_url]="get_categ_patch_url(uc)"></util-realtime-textinput></td>
            <td><util-realtime-textinput [current_value]="uc.profit_expression" [property_key]="'profit_expression'" [patch_url]="get_categ_patch_url(uc)"></util-realtime-textinput></td>
            <td><util-realtime-textinput [current_value]="uc.overhead_costs_expression" [property_key]="'overhead_costs_expression'" [patch_url]="get_categ_patch_url(uc)"></util-realtime-textinput></td>
            <td><util-realtime-textinput [current_value]="uc.issue_invoice_template" [property_key]="'issue_invoice_template'" [patch_url]="get_categ_patch_url(uc)"></util-realtime-textinput></td>

        </tr>
    </tbody>

</table>

<button class="btn btn-danger btn-sm" (click)="drop_cf()">
    Drop CF
</button>
