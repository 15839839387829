import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from './api-endpoints.service';
import { UserData } from "./user-data.service";
import { QuarterService } from './quarter.service';
import { CurrentSync } from '../utils/current_sync';
import {UserCategoryData} from "./user-categories-data.service";
import {PricelistData} from "./pricelists-data.service";

export interface PricingData
{
    pricing_id: string;
    cost_per_unit: number;
    currency: string;
    discount_expression: string,

    service: ServiceData;
    pricelist: PricelistData;

    fk_pricelists_id: string;
    fk_services_id: string;
}


export interface ServiceData
{
    services_id: string;
    group: string;
    name: string;
    unit_name: string;
    unit_unit: string;
    alias: string;
    crm_product_number: string;

    // facility: Facility;
    pricings: PricingData[];

    fk_facilities_id: string;
}

@Injectable({
    providedIn: 'root'
})
export class ServicesService extends CurrentSync<ServiceData[]>
{

    constructor(
        private httpc: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService
    ) { super([]) }

    load_services(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter)
    {
        this.httpc.get<ServiceData[]>(this.urls.SERVICE_PRICING_TABLE_URL(quarter, facility))
            .subscribe(data => this.next_current(data));
    }

    load_services_light(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter)
    {
        this.httpc.get<ServiceData[]>(this.urls.SERVICES_FOR_FAC_URL(quarter, facility))
            .subscribe(data => this.next_current(data));
    }


    new_service(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter): Observable<ServiceData>
    {
        return this.httpc.post<ServiceData>(this.urls.SERVICES_URL(quarter), { fk_facilities_id: facility });
    }

    get_service_patch_url(id: string, quarter: string = this.qt.selected_quarter)
    {
        return this.urls.SERVICE_URL(quarter, id);
    }

    get_pricing_patch_url(id: string, quarter: string = this.qt.selected_quarter)
    {
        return this.urls.PRICING_URL(quarter, id);
    }

    get_pricings(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter) : Observable<PricingData[]>
    {
        return this.httpc.get<PricingData[]>((facility) ? this.urls.PRICINGS_URL_FOR_FAC(quarter, facility) : this.urls.PRICINGS_URL(quarter));
    }


    delete_service(services_id: string, qt: string = this.qt.selected_quarter) {
        return this.httpc.delete(this.urls.SERVICE_URL(qt, services_id), { responseType: "text" })
    }
}
