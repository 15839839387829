import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from './api-endpoints.service';
import { QuarterService } from './quarter.service';
import { CurrentSync } from '../utils/current_sync';

export interface PricelistData
{
    pricelists_id: string;
    crm_name: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})
export class PricelistsDataService extends CurrentSync<PricelistData[]> {

    constructor(
        private httpc: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService
    )
    {
        super();
    }

    fetch_all(): void
    {
        this.httpc.get<PricelistData[]>(this.urls.PRICELISTS_URL(this.qt.selected_quarter))
            .subscribe((v) => this.next_current(v));
    }
}
