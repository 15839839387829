import { Component, OnInit } from '@angular/core';
import { QuarterService, FacilityInfo } from '../../services/quarter.service';
import { Observable } from 'rxjs';
import { UserService } from '../../utils/user/user.service';
import { User } from '../../utils/user/user_standard';


@Component({
    selector: 'app-nav-panel',
    templateUrl: './nav-panel.component.html',
    styleUrls: ['./nav-panel.component.scss']
})
export class NavPanelComponent implements OnInit {

    cf_list: Observable<FacilityInfo[]>;
    quart_list: Observable<String[]>;
    sel_quarter: Observable<String>;
    user$: Observable<User>;

    constructor(
        private quarters: QuarterService,
        private user_service: UserService<User>
    )
    {
        this.user$ = this.user_service.current$;
    }

    ngOnInit(): void
    {
        this.cf_list = this.quarters.facility_list$;
        this.quart_list = this.quarters.quarter_list$;
        this.sel_quarter = this.quarters.selected_quarter$;
    }
}
