import { Component, OnDestroy, OnInit} from '@angular/core';
import { QuarterService } from '../../services/quarter.service';
import { QuarterStatistics, StatsService } from '../../services/stats.service';
import { Observable, Subscription } from 'rxjs';


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss']
})
export class StatsComponent implements OnInit, OnDestroy
{
  public stats$: Observable<QuarterStatistics>;

  private _current_quarter_subscription: Subscription;

  constructor(
    private stats_service: StatsService,
    private qs: QuarterService
  ) { }

  ngOnInit(): void {
    this._current_quarter_subscription = this.qs.selected_quarter$.subscribe(q => this.stats_service.load_statistics(q));
    this.stats_service.load_statistics();
    this.stats$ = this.stats_service.current$;
  }

  ngOnDestroy(): void
  {
    this._current_quarter_subscription.unsubscribe();
  }
}
