
<div class="util-container">
    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Import from previous quarter</h5>

            <p>
                Fill the selected quarter with metadata from preceeding quarter. Copied are: users, user categories, services, prices.
                Service utilization and report statuses are not copied.
            </p>
            <p>
                This should be done only once when quarter starts, since <strong class="text-danger">all quarter data is purged from the selected quarter before the operation.</strong>
            </p>


        </div>

        <div class="card-footer">
            <button class="btn btn-sm btn-ceitec" (click)="on_import_quarter()">Import from previous quarter</button>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Import utilization from excel</h5>
            <p>
                Parse excel file and load service utilization data for the quarter.
                Services, pricelists, users and user categories should be already present in the database, so the utlization data can be correctly mapped.
                If user or user category is not found, system automatically creates a new one and you can modify it's properties later.
            </p>




        </div>

        <div class="card-footer">
            <div class="input-group input-group-sm">
                <input #uploader type="file" multiple accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                <div style="margin-left: auto"><button class="btn btn-sm btn-ceitec" (click)="on_import_excel(uploader.files)">Import .xlsx file</button></div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Import services and prices from CRM</h5>
            <p>
                Firstly, services are imported. New services are created, those already present and mapped to the CRM are updated,
                those already present and not mapped to the CRM are untouched.
                Secondly, pricing (costs per unit) are updated to all services and user categories.
                User categories are not imported, import process takes into consideration only prices for categories already present and mapped to the CRM.
            </p>



        </div>

        <div class="card-footer">
            <button class="btn btn-sm btn-ceitec" (click)="on_import_services()">Import services and prices</button>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Purge quarter</h5>
            <p>
                Remove all data from the quarter.
            </p>

        </div>

        <div class="card-footer">
            <div class="btn-group btn-group-sm">
                <button class="btn btn-ceitec" (click)="on_purge_quarter()">Purge quarter</button>
                <button class="btn btn-ceitec" (click)="on_purge_utilization()">Purge utilization</button>
                <button class="btn btn-ceitec" (click)="on_purge_services()">Purge services</button>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Initialize quarter</h5>
            <p>
                Fill the database with default information about facilities. For development purposes.
            </p>
            <p>
                <strong class="text-danger">All quarter data is purged before the operation.</strong>
            </p>

        </div>

        <div class="card-footer">
            <button class="btn btn-sm btn-ceitec" (click)="on_initialize_quarter()">Initialize quarter</button>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Backup database</h5>
            <p>
                Copy and save whole current system state.
            </p>

        </div>

        <div class="card-footer">
            <button class="btn btn-sm btn-ceitec" (click)="on_db_backup()">Backup database</button>
        </div>
    </div>

    <div class="card">
        <div class="card-body">
            <h5 class="card-title">Create new CF</h5>


        </div>

        <div class="card-footer">
            <div class="input-group input-group-sm">
                <input #cfid type="text" class="form-control form-control-sm" placeholder="CF identifier (e.g. BIC, CRYO...)" />

                <div class="input-group-append">
                    <button class="btn btn-ceitec btn-sm" (click)="create_cf(cfid.value)">Create</button>
                </div>
            </div>
        </div>
    </div>
</div>

