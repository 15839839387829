<div class="container-fluid">
    <div class="row">

        <!-- User merging tool -->
        <div class="col-7 users-table-container">

            <!-- Users content -->
            <util-entity-table [data]="user_data$ | async" [metadata]="USERS_TABLE_META"></util-entity-table>

            <div class="card mb-2" *ngIf="user_data$ | async as user_data">
                <div class="card-header d-flex align-items-center ">
                    <div class="fw-bold me-2">Merge users:</div>
                    <div class="form-group flex-grow-1 mx-2">
                        <label class="form-label">User to delete (merge from)</label>
                        <select class="form-control" (change)="merge_from_user$.next($event.target.value)">
                            <option value="">Select user</option>
                            <option *ngFor="let opt of (user_data | usersForSelect | keyvalue: asis)"
                                    [value]="opt.key"
                            >
                                {{ opt.value }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group flex-grow-1 mx-2">
                        <label class="form-label">User to preserve (merge to)</label>
                        <select class="form-control" (change)="merge_to_user$.next($event.target.value)">
                            <option value="">Select user</option>
                            <option *ngFor="let opt of (merge_to_user_options$ | async | usersForSelect | keyvalue: asis)"
                                    [value]="opt.key"
                            >
                                {{ opt.value }}
                            </option>
                        </select>
                    </div>
                    <button class="btn btn-ceitec align-self-end" (click)="merge_button_clicked$.next(true)">Merge</button>
                </div>
            </div>
        </div>

        <div class="col categ-prices-table-container">
            <!-- User categories content -->
            <util-entity-table [data]="user_categories_data$ | async" [metadata]="USERS_CATEGORIES_META"></util-entity-table>

            <!-- Pricelists content -->
            <util-entity-table [data]="pricelists_data$ | async" [metadata]="PRICELISTS_META"></util-entity-table>

        </div>
    </div>

</div>
