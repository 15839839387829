<div class="nav-bar-main mb-2">
    <div class="dropdown quarter-select">
        <!--suppress CheckImageSize -->
        <img src="/assets/ceitec_logo.jpg" alt="Ceitec logo" width="32"/>
        &nbsp;
        <a class="d-inline" routerLink="/{{ sel_quarter | async }}/overview">{{ sel_quarter | async | quarter }}</a>

        <div class="dropdown-items quarter-dropdown-items">
            <a *ngFor="let q of (quart_list | async).reverse()" class="dropdown-item" routerLink="/{{ q }}/overview">{{ q | quarter }}</a>
        </div>
    </div>

    &nbsp;&nbsp;

    <a routerLinkActive="link-active" routerLink="overview">Overview</a>
    <a routerLinkActive="link-active" routerLink="users">Users & Categories</a>
    <a routerLinkActive="link-active" routerLink="user-reporting">User reporting</a>

    <div class="spacer"></div>

    <a *ngFor="let f of cf_list | async"
       [routerLink]="['facility', f.facilities_id]"
       routerLinkActive="link-active">{{ f.facilities_id }}</a>

    <div class="flex-filler"></div>

    <span *ngIf="user$ | async as usr">
		<i class="fas fa-user"></i>
		{{ usr.fullname }}
	</span>

</div>
