import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {map, mergeMap, scan, take} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, from, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ApiEndpointsService} from 'src/app/services/api-endpoints.service';
import {QuarterService} from 'src/app/services/quarter.service';
import {UserReportData, UserReportsService, UserReportStatus} from 'src/app/services/user-reporting.service';
import {MessagesService} from "src/app/utils/messages/messages.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LocalStorageService} from "src/app/utils/local-storage/local-storage.service";
import {User} from "../../utils/user/user_standard";

type SelectionMap = { [key: string]: boolean };

@Component({
    selector: 'app-user-reporting',
    templateUrl: './user-reporting.component.html',
    styleUrls: ['./user-reporting.component.scss']
})
export class UserReportingComponent implements OnInit
{
    user_reports$: Observable<UserReportData[]>;
    selection_map$: BehaviorSubject<SelectionMap> = new BehaviorSubject<SelectionMap>({ });

    user_selected_reports$: Observable<UserReportData[]>;
    show_actions_panel$: Observable<boolean>;

    active_report$: Observable<UserReportData> = new BehaviorSubject<UserReportData>(null);
    UserReportStatus: typeof UserReportStatus = UserReportStatus;

    selection_reports_to_send$: Observable<UserReportData[]>;
    selection_reports_to_invoice_or_later$: Observable<UserReportData[]>;
    selection_reports_to_crmgen$: Observable<UserReportData[]>;
    selection_reports_to_resolve$: Observable<UserReportData[]>;
    processing_status$: BehaviorSubject<string> = new BehaviorSubject<string>("");

    constructor(
        private user_reports_service: UserReportsService,
        public qt_service: QuarterService,
        private urls: ApiEndpointsService,
        private messages: MessagesService,
        private dom_sanitizer: DomSanitizer,
        private aroute: ActivatedRoute,
        private router: Router,
        private loc: LocalStorageService
    ) {
        this.user_reports$ = this.user_reports_service.current$;
    }

    ngOnInit(): void {
        this.user_reports_service.load_reports();
        this.aroute.params.subscribe(pm => console.log(pm));
        this.active_report$ = combineLatest(this.user_reports$, this.aroute.params)
            .pipe(
                map((value) => this.select_active_report(value[0], value[1]))
            )

        this.user_selected_reports$ = combineLatest(this.user_reports$, this.selection_map$).pipe(
            map(([repots, selections]) => repots.filter(r => selections[r.user_reports_id]))
        )

        this.show_actions_panel$ = this.user_selected_reports$.pipe(
            map(u  => !!u.length)
        );

        this.selection_reports_to_send$ = this.user_selected_reports$.pipe(
            map(reports => reports.filter(r => this.is_sendable(r)))
        )

        this.selection_reports_to_invoice_or_later$ = this.user_selected_reports$.pipe(
            map(reports => reports.filter(r => this.is_invoicable(r)))
        )

        this.selection_reports_to_crmgen$ = this.user_selected_reports$.pipe(
            map(reports => reports.filter(r => this.is_crm_order_generatable(r)))
        )

        this.selection_reports_to_resolve$ = this.user_selected_reports$.pipe(
            map(reports => reports.filter(r => this.is_resolvable(r)))
        )
    }

    change_all_selection(selection_map: SelectionMap, all_reports: UserReportData[], selected: boolean) {
        if (selected) all_reports.forEach((r) => selection_map[r.user_reports_id] = true)
        else selection_map = { };
        this.selection_map$.next(selection_map);
    }

    change_selection(selection_map: SelectionMap, selected: boolean, target_report: UserReportData) {
        if (selected) selection_map[target_report.user_reports_id] = true;
        else delete selection_map[target_report.user_reports_id];
        this.selection_map$.next(selection_map);
    }

    is_selected(selection_map: SelectionMap, target_report: UserReportData) {
        return selection_map[target_report.user_reports_id] == true;
    }

    select_active_report(urd: UserReportData[], params: Params) {
        console.log(`Selecting active report ${params} ${urd}`);
        if (!urd) return null;
        if (params.reportid)
        {
            let report = urd.find(u => u.user_reports_id == params.reportid);
            if (report) {
                this.loc.save("last_user_report_id", report.user_reports_id);
                return report;
            }
        }
        else
        {
            // No param given - check localstorage, if not available, route to first one
            let report_id = this.loc.retrieve_and_clar<string>("last_user_report_id") ?? urd[0].user_reports_id;
            this.router.navigateByUrl(this.router.url + "/" + report_id);
        }

        return null;
    }

    get_report_classes(item: UserReportData): string {
        let classes = item.status.replace(/ /g, "_")
            .replace(/,/g, "")
            .toLowerCase();
        if (!item.user.contact_email && this.is_sendable_status(item))  //&& item.user.user_category.include_in_user_reports)
            classes += " text-danger";
        if (!item.user.user_category.include_in_user_reports)
            classes += " external";

        return classes;
    }


    get_iframe_url(item: UserReportData): string {
        return environment.api_base +
            this.urls.USER_REPORT_PREVIEW(this.qt_service.selected_quarter, item.user_reports_id);
    }

    is_sendable_status(report: UserReportData) {
        return (report.status == UserReportStatus.NotSentYet ||
            report.status == UserReportStatus.Refused || report.status == UserReportStatus.Approved)
    }

    is_sendable(report: UserReportData) {
        return this.is_sendable_status(report) && report.user.contact_email; // && report.user.user_category.include_in_user_reports && report.user.contact_email;
    }

    identify_report(index, report: UserReportData) {
        return report.user_reports_id;
    }

    send_report(r: UserReportData) {
        this.user_reports_service.send_report(r)
            .subscribe(
                (r) => {
                    this.messages.success(r);
                    this.user_reports_service.load_reports()
                }     ,
                (e) => {
                    this.messages.error("Failed to send the report.");
                    console.error(e)
                }
            );
    }

    is_invoicable(r: UserReportData) {
        return r.status == UserReportStatus.Approved; //  || r.status == UserReportStatus.NotSentYet || r.status == UserReportStatus.Sent;
    }

    is_crm_order_generatable(r : UserReportData) {
        return r.status == UserReportStatus.NotSentYet && !r.user.user_category.include_in_user_reports;
    }

    crm_generate_order(r: UserReportData) {
        this.user_reports_service.generate_crm_order(r).subscribe(
            (r) => {
                this.messages.success(r);
                this.user_reports_service.load_reports()
            }     ,
            (e) => {
                this.messages.error("Failed to send the report.");
                console.error(e)
            }
        )
    }

    invoice_now(r: UserReportData) {
        this.user_reports_service.invoice_now(r).subscribe(() => this.user_reports_service.load_reports());
    }

    resolve(r: UserReportData) {
        this.user_reports_service.resolve(r).subscribe(() => this.user_reports_service.load_reports())
    }

    is_resolvable(r: UserReportData) {
        return r.status != UserReportStatus.Resolved && r.status != UserReportStatus.Invoiced;
    }

    send_multiple_click(target_count: number) {
        // Sending
        this.selection_reports_to_send$.pipe(
            take(1),
            mergeMap(from),
            // From now, all selected reports are emmited one by one
            // Capture them and by merge map emit http request for each (order is irrelevant)
            mergeMap(report => this.user_reports_service.send_report(report)),
            scan((acc, value) => acc + 1 , 0)
        ).subscribe((count_done) => {
                this.processing_status$.next(`${count_done} of ${target_count} reports sent.`);
            }, null,
            () => {
                this.processing_status$.next("");
                this.user_reports_service.load_reports();
            });
    }

    crmgen_multiple_click(target_count: number) {
        // Sending
        this.selection_reports_to_crmgen$.pipe(
            take(1),
            mergeMap(from),
            // From now, all selected reports are emmited one by one
            // Capture them and by merge map emit http request for each (order is irrelevant)
            mergeMap(report => this.user_reports_service.generate_crm_order(report)),
            scan((acc, value) => acc + 1 , 0)
        ).subscribe((count_done) => {
                this.processing_status$.next(`${count_done} of ${target_count} reports generated.`);
            }, null,
            () => {
                this.processing_status$.next("");
                this.user_reports_service.load_reports();
            });
    }


    invoice_multiple_click(target_count: number) {
        this.selection_reports_to_invoice_or_later$.pipe(
            take(1),
            mergeMap(from),
            mergeMap(report => this.user_reports_service.invoice_now(report)),
            scan((acc, value) => acc + 1 , 0)
        ).subscribe((count_done) => {
                this.processing_status$.next(`${count_done} of ${target_count} reports invoiced.`);
            }, null,
            () => {
                this.processing_status$.next("");
                this.user_reports_service.load_reports();
            });
    }

    resolve_click(target_count: number) {
        this.selection_reports_to_resolve$.pipe(
            take(1),
            mergeMap(from),
            mergeMap(report => this.user_reports_service.resolve(report)),
            scan((acc, value) => acc + 1 , 0)
        ).subscribe((count_done) => {
                this.processing_status$.next(`${count_done} of ${target_count} reports set as resolved.`);
            }, null,
            () => {
                this.processing_status$.next("");
                this.user_reports_service.load_reports();
            });
    }


}
