import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { EntityTableData, EntityTableMetadata } from './entity_table_metadata';

@Component({
    selector: 'util-entity-table',
    templateUrl: './entity-table.component.html',
    styleUrls: ['./entity-table.component.css']
})
export class EntityTableComponent implements OnInit {
    
    @Input() data: EntityTableData[];
    @Input() metadata: EntityTableMetadata;

    constructor(
        private http_c: HttpClient
    ) { }
    
    ngOnInit(): void {
    }

    spawn_row()
    {
        this.http_c.post<any[]>(this.metadata.api_url, { }).subscribe(result => {
            if (!Array.isArray(result)) result = [result];
            this.data.unshift(...result);
        });
    }
    
}
