import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndpointsService } from './api-endpoints.service';
import { QuarterService } from './quarter.service';
import { CurrentSync } from '../utils/current_sync';

export interface ServiceUsageData
{
    services_usages_id: string;
    units_used: number;
    group: string;
    comments_for_user: string;
    comments_for_cf: string;
    discount_expression: string;

    dt_measurement: Date;

    fk_projects_id: string;
    fk_services_id: string;
}


@Injectable({
  providedIn: 'root'
})
export class UtilizationService extends CurrentSync<ServiceUsageData[]>
{
  constructor(
    private httpc: HttpClient,
    private urls: ApiEndpointsService,
    private qt: QuarterService
  ) { super([]); }

  load_utilizations(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter)
  {
      this.httpc.get<ServiceUsageData[]>(this.urls.SERVICE_USAGES_FOR_FACILITY(quarter, facility))
          .subscribe(data => this.next_current(data));
  }

  new_utilization(facility: string = this.qt.selected_facility, quarter: string = this.qt.selected_quarter): Observable<ServiceUsageData>
  {
      return this.httpc.post<ServiceUsageData>(this.urls.SERVICE_USAGES_URL(quarter), { });
  }

  get_patch_url(id: string, quarter: string = this.qt.selected_quarter)
  {
      return this.urls.SERVICE_USAGE_URL(quarter, id);
  }

    delete_util(id: string, qt: string = this.qt.selected_quarter) {
        return this.httpc.delete(this.urls.SERVICE_USAGE_URL(qt, id), { responseType: "text" })
    }
}
