import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CurrentSync } from "../utils/current_sync";
import { ApiEndpointsService } from "./api-endpoints.service";
import { QuarterService } from "./quarter.service";
import {UserCategoryData} from "./user-categories-data.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

export interface UserData
{
    users_id: string;
    name: string;
    contact_email: string;
    alias: string;

    fk_user_categories_id: string;
    user_category: UserCategoryData;
}

@Injectable({
    providedIn: 'root'
})
export class UserDataService extends CurrentSync<UserData[]> {

    user_by_categories$: Observable<UserCategoryData[]>;

    constructor(
        private http_c: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService
    )
    {
        super();
        // this.user_by_categories$ = this.current$.pipe(
        //     map(users => {
        //         const orphans: UserData[] = [];
        //         for (let user of users) {
        //             if (!user.user_category) {
        //                 orphans.push(user)
        //             }
        //         }
        //
        //     })
        // )
    }

    _to_user_category(user_category_datas: UserCategoryData[], user: UserData) {
    }

    fetch_all()
    {
        this.http_c.get<UserData[]>(this.urls.USERS_URL(this.qt.selected_quarter))
            .subscribe((v) => this.next_current(v));
    }


    merge_users(from: string, to: string, quarter: string = this.qt.selected_quarter) {
        return this.http_c.get(this.urls.USER_MERGE_URL(quarter, from, to), { responseType: "text"});
    }

}
