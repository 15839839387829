import { Component, OnInit } from '@angular/core';
import { QuarterService } from '../../services/quarter.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { QuarterHelperService } from '../../services/quarter-helper.service';
import { Subject, Subscription } from 'rxjs';

@Component({
    selector: 'app-quarter',
    templateUrl: './quarter.component.html',
    styleUrls: ['./quarter.component.scss']
})
export class QuarterComponent implements OnInit
{
    private _sub: Subscription;

    constructor(
        private quarters: QuarterService,
        private aroute: ActivatedRoute,
        private router: Router,
        private qhelper: QuarterHelperService
    ) { }

    ngOnInit(): void
    {
        this.quarters.loadQuarterList();

        this._sub = this.aroute.params.subscribe(params => {
            let qt = params["quarter"];
            if (!qt || !this.qhelper.is_quarter(qt)) qt = this.qhelper.currentQuarter();
            this.quarters.selectQuarter(qt);
        });
    }

    ngOnDestroy(): void
    {
        this._sub.unsubscribe();
    }

}
