import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MessagesService } from '../messages.service';
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Message, MessageType } from '../message';

@Component({
    selector: 'util-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit, OnDestroy
{    
    /**
     * Limit for amount of messages that can be displayed at one moment.
     */
    @Input() message_display_limit = 5;
    /*
     * List of messages currently ready to be rendered by the template
     */
    public message_pool: Message[] = [ ];

    private _subscription: Subscription = null;

    constructor(
        private messages_service: MessagesService
    ) { }

    on_new_message(message: Message)
    {
        this.message_pool.unshift(message);

        // Set timout for the message
        if (!message.forever)
        {
            of(message).pipe(
                delay(message.DELAY_BASE)
            ).subscribe(m => this.dispose_message(m));
        }
        
        if (this.message_display_limit < this.message_pool.length)
        {
            this.dispose_last_message();
        }
    }

    on_message_click(message: Message)
    {
        this.dispose_message(message);
    }

    dispose_last_message()
    {
        this.message_pool.pop();
    }

    dispose_message(message: Message)
    {
        let index = this.message_pool.indexOf(message);
        if (this.message_pool.indexOf(message) !== -1)
        {
            this.message_pool.splice(index, 1);
        }
    }
    
    ngOnInit(): void 
    {
        this._subscription = this.messages_service.message_stream.subscribe(m => this.on_new_message(m));
    }


    ngOnDestroy(): void
    {
        this._subscription.unsubscribe();
    }
    
}
