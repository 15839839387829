import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuarterComponent } from './components/quarter/quarter.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FacilityOverviewComponent } from './components/facility-overview/facility-overview.component';
import { FacilityComponent } from './components/facility/facility.component';
import { UsersComponent } from './components/users/users.component';
import { ServicesComponent } from './components/services/services.component';
import { UserReportingComponent } from './components/user-reporting/user-reporting.component';
import { LatestQuarterGuard } from './guards/latest-quarter.guard';
import { ProjectsComponent } from './components/projects/projects.component';
import { UtilizationComponent } from './components/utilization/utilization.component';
import { CrmImportWaiterComponent } from "./components/crm-import-waiter/crm-import-waiter.component";
import {MultiquarterReportComponent} from "./components/multiquarter-report/multiquarter-report.component";
import {CfSetupComponent} from "./components/cf-setup/cf-setup.component";

const routes: Routes = [
    {
        path: "crm_wait",
        component: CrmImportWaiterComponent
    },
    {
        path: "overviews",
        component: MultiquarterReportComponent
    },
    {
        path: ":quarter",
        component: QuarterComponent,
        canActivate: [LatestQuarterGuard],
        children:
        [
            {
                path: "overview",
                component: OverviewComponent
            },
            {
                path: "users",
                component: UsersComponent
            },
            {
                path: "services",
                component: ServicesComponent
            },
            {
                path: "user-reporting/:reportid",
                component: UserReportingComponent
            },
            {
                path: "user-reporting",
                component: UserReportingComponent
            },
            {
                path: "facility/:fac_id",
                component: FacilityComponent,
                children:
                [
                    {
                        path: "overview",
                        component: FacilityOverviewComponent
                    },
                    {
                        path: "utilization",
                        component: UtilizationComponent
                    },
                    {
                        path: "services",
                        component: ServicesComponent
                    },
                    {
                        path: "projects",
                        component: ProjectsComponent
                    },
                    {
                        path: "cf-setup",
                        component: CfSetupComponent
                    }
                ]

            }
        ]
    },
    {
        path: "**",
        redirectTo: "/latest"
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
