import { BehaviorSubject, Observable } from 'rxjs';

/**
 * Abstract class that provides single managed value.
 */
export abstract class CurrentSync<T>
{
    protected _current$: BehaviorSubject<T>;
    public current$: Observable<T>;
    public current: T;

    constructor(initial_value: T = null)
    {
        this._current$  = new BehaviorSubject(initial_value);
        this.current    = initial_value;
        this.current$   = this._current$.asObservable();
    }

    next_current(value: T = this.current)
    {
        this.current = value;
        this._current$.next(value);
    }
}