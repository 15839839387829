import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class EDUIDAuthInterceptor implements HttpInterceptor
{
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(
            catchError((res: HttpErrorResponse) => {

                if (res.status == 401) // Unauthorized
                {
                    // Go to login page.
                    location.href = "/api/saml2/login";
                }

                return throwError(res);
            }

        ));
    }
}
