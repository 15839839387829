import { RealtimeNumberinputComponent } from './utils/realtime-inputs/realtime-numberinput/realtime-numberinput.component';
import { RealtimeSelectComponent } from './utils/realtime-inputs/realtime-select/realtime-select.component';
import { RealtimeTextareaComponent } from './utils/realtime-inputs/realtime-textarea/realtime-textarea.component';
import { RealtimeTextinputComponent } from './utils/realtime-inputs/realtime-textinput/realtime-textinput.component';
import { EntityTableRowComponent } from './utils/entity-table/entity-table-row/entity-table-row.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavPanelComponent } from './components/nav-panel/nav-panel.component';
import { QuarterPipe } from './pipes/quarter.pipe';
import { QuarterComponent } from './components/quarter/quarter.component';
import { OverviewComponent } from './components/overview/overview.component';
import { FacilityComponent } from './components/facility/facility.component';
import { UsersComponent } from './components/users/users.component';
import { ServicesComponent } from './components/services/services.component';
import { UserReportingComponent } from './components/user-reporting/user-reporting.component';
import { EntityTableComponent } from "./utils/entity-table/entity-table.component";
import { MessagesComponent } from "./utils/messages/messages/messages.component";
import { environment } from 'src/environments/environment';

import { BaseUrlInterceptor } from './utils/base-url.interceptor';
import { UserService } from "./utils/user/user.service";
import { LocalStorageService } from "./utils/local-storage/local-storage.service";
import { EDUIDAuthInterceptor } from "./utils/eduid-auth.interceptor";
import { MessagesService } from "./utils/messages/messages.service";
import { ProjectsComponent } from './components/projects/projects.component';
import { UtilizationComponent } from './components/utilization/utilization.component';
import { ToolboxComponent } from './components/toolbox/toolbox.component';
import { StatsComponent } from './components/stats/stats.component';
import { CachedSrcDirective } from "./utils/cached-src-directive";
import { UsersForSelectPipe } from './pipes/users-for-select.pipe';
import { FacilityOverviewComponent } from './components/facility-overview/facility-overview.component';
import {FormsModule} from "@angular/forms";
import { CrmImportWaiterComponent } from './components/crm-import-waiter/crm-import-waiter.component';
import {ServicesForSelectPipe} from "./pipes/services-for-select.pipe";
import {UserCategsForSelectPipe} from "./pipes/user-categs-for-select.pipe";
import {ContenteditableBindfixDirective} from "./utils/realtime-inputs/realtime-textinput/input-bindfix.directive";
import {UserProjectsForSelectPipe} from "./pipes/user-projects-for-select.pipe";
import { MultiquarterReportComponent } from './components/multiquarter-report/multiquarter-report.component';
import { CfSetupComponent } from './components/cf-setup/cf-setup.component';

@NgModule({
    declarations: [
        AppComponent,
        NavPanelComponent,
        QuarterPipe,
        QuarterComponent,
        OverviewComponent,
        FacilityComponent,
        UsersComponent,
        ServicesComponent,
        UserReportingComponent,
        ProjectsComponent,
        UtilizationComponent,
        EntityTableComponent,
        EntityTableRowComponent,
        MessagesComponent,
        RealtimeTextinputComponent,
        RealtimeTextareaComponent,
        RealtimeSelectComponent,
        RealtimeNumberinputComponent,
        ToolboxComponent,
        StatsComponent,
        CachedSrcDirective,
        ContenteditableBindfixDirective,
        UsersForSelectPipe,
        FacilityOverviewComponent,
        CrmImportWaiterComponent,
        ServicesForSelectPipe,
        UserCategsForSelectPipe,
        UserProjectsForSelectPipe,
        MultiquarterReportComponent,
        CfSetupComponent
    ],

    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule
    ],

    providers: [
        LocalStorageService,
        MessagesService,
        UserService,
        { provide: "BASE_URL", useValue: environment.api_base },
        { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: EDUIDAuthInterceptor, multi: true },

    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
