<td *ngFor="let col of metadata.render_columns" [class]="col.property_key"  [class.empty]="get_data_value(col.property_key).toString() == ''">
    <ng-container *ngIf="col.editable">
        <!-- <util-realtime-textinput [property_key]="col.property_key" [patch_url]="metadata.api_url" [current_value]="row_data[col.property_key]"></util-realtime-textinput> -->

        <ng-container [ngSwitch]="col.input_type">
            <util-realtime-textinput   *ngSwitchCase="input_types.TEXT"     [property_key]="col.property_key" [patch_url]="metadata.api_url + '/' + get_data_value(metadata.id_property_name).toString()" [current_value]="row_data[col.property_key]"></util-realtime-textinput>
            <util-realtime-textarea    *ngSwitchCase="input_types.TEXTAREA" [property_key]="col.property_key" [patch_url]="metadata.api_url + '/' + get_data_value(metadata.id_property_name).toString()" [current_value]="row_data[col.property_key]"></util-realtime-textarea>
            <util-realtime-numberinput *ngSwitchCase="input_types.NUMBER">  [property_key]="col.property_key" [patch_url]="metadata.api_url + '/' + get_data_value(metadata.id_property_name).toString()" [current_value]="row_data[col.property_key]"</util-realtime-numberinput>
            <util-realtime-select      *ngSwitchCase="input_types.SELECT"   [property_key]="col.property_key" [patch_url]="metadata.api_url + '/' + get_data_value(metadata.id_property_name).toString()" [current_value]="row_data[col.property_key]"
                                        [value_map]="col.value_map" [value_map$]="col.value_map$" ></util-realtime-select>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!col.editable">
        <div class="px-1">{{ get_data_value(col.property_key) | date:'MMM d, y H:mm' }}</div>
    </ng-container>

</td>
