import { Injectable } from '@angular/core';
import { CurrentSync } from '../current_sync';
import { User } from './user_standard';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UserService<T extends User> extends CurrentSync<User>
{
    private _users$: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([ ])
    users$: Observable<User[]> = this._users$.asObservable();

    constructor(
        private http_c: HttpClient
    ) 
    { 
        super();
        this.current$.subscribe();
        this.fetch_current_user();
    }

    /**
     * Fetch user that is currently logged in and reflect change through the behavior subject
     */
    fetch_current_user()
    {
        this.http_c.get<User>("user").subscribe((u) => this._current$.next(u));
    }

    fetch_all_users()
    {
        this.http_c.get<User[]>("users").subscribe((u) => this._users$.next(u));
    }

    impersonate_user(u: User)
    {
        this.http_c.post<User>("impersonate", { impersonate: u.primary_email }).subscribe(p => this.fetch_current_user());
    }

    reset_impersonation()
    {
        return this.http_c.post<User>("impersonate", { impersonate: "" }).subscribe(p => this.fetch_current_user());
    }
}
