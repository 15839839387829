<div *ngIf="message_pool?.length" class="messages-container">
    <ul class="messages-list-container">
        <li *ngFor="let m of message_pool"
            [ngClass]="m.type"
            (click)="on_message_click(m)"
        >
            <i></i>
            <p class="m-0">{{ m.text }}</p>
        </li>
    </ul>
</div>
