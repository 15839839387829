<div class="rt-select-container" (click)="show_select = true">
    <select *ngIf="show_select" #divref class="rt-select rt-{{ status }}" (blur)="show_select = false"
        (change)="input_refresh(divref.value); try_submit()"
    >
        <option value="" [selected]="current_value == '' || current_value == null"> - </option>

        <ng-container *ngIf="value_map">
            <option *ngFor="let opt of value_map | keyvalue: valueAscOrder"
                [selected]="opt.key == current_value"
                [value]="opt.key"
            >
                {{ opt.value }}
            </option>
        </ng-container>

        <ng-container *ngIf="value_map$">
            <option *ngFor="let opt of value_map$ | async | keyvalue"
                    [selected]="opt.key == current_value"
                    [value]="opt.key"
            >
                {{ opt.value }}
            </option>
        </ng-container>
    </select>

    <div *ngIf="!show_select" class="ps-1">
        <ng-container *ngIf="value_map$ | async as vm">{{ vm[current_value] }}</ng-container>
        <ng-container *ngIf="value_map">{{ value_map[current_value] }}</ng-container>
    </div>

</div>


