import { UserCategoriesDataService, UserCategoryData } from '../../services/user-categories-data.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { EntityTableMetadata, InputType } from '../../utils/entity-table/entity_table_metadata';
import { ApiEndpointsService } from '../../services/api-endpoints.service';
import { QuarterService } from '../../services/quarter.service';
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from 'rxjs';
import { UserData, UserDataService } from "../../services/user-data.service";
import {PricelistData, PricelistsDataService} from "../../services/pricelists-data.service";
import {catchError, exhaustMap, filter, map, retry, tap, withLatestFrom} from "rxjs/operators";
import {MessagesService} from "../../utils/messages/messages.service";

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy
{
    USERS_TABLE_META: EntityTableMetadata =
    {
        id_property_name: "users_id",
        table_caption: "Users",
        render_columns:
        [
            {
                display_name: "Name",
                property_key: "name",
                editable: true,
                input_type: InputType.TEXT
            },
            {
                display_name: "Contact",
                property_key: "contact_email",
                editable: true,
                input_type: InputType.TEXT
            },
            {
                display_name: "Type",
                property_key: "type",
                editable: true,
                input_type: InputType.TEXT
            }
        ]
    };

    USERS_CATEGORIES_META: EntityTableMetadata =
    {
        id_property_name: "user_categories_id",
        table_caption: "User categories",
        render_columns:
        [
            {
                display_name: "Category name",
                property_key: "name",
                editable: true,
                input_type: InputType.TEXT
            },
            {
                display_name: "Alias",
                property_key: "alias",
                editable: true,
                input_type: InputType.TEXT
            }
            // Last is appendend in nginit since we need observable
        ]
    };

    PRICELISTS_META: EntityTableMetadata =
    {
        id_property_name: "pricelists_id",
        table_caption: "Pricelists",
        render_columns:
        [
            {
                display_name: "Pricelist name",
                property_key: "name",
                editable: true,
                input_type: InputType.TEXT
            },
            {
                display_name: "CRM name",
                property_key: "crm_name",
                editable: true,
                input_type: InputType.TEXT
            }
        ]
    };




    user_data$: Observable<UserData[]>;
    user_categories_data$: Observable<UserCategoryData[]>;
    pricelists_data$: Observable<PricelistData[]>;

    merge_from_user$: BehaviorSubject<string> = new BehaviorSubject<string>("");
    merge_to_user$: BehaviorSubject<string> = new BehaviorSubject("");
    merge_to_user_options$: Observable<UserData[]>;
    merge_button_clicked$: Subject<boolean> = new Subject<boolean>();
    _merge_sub$: Subscription;

    constructor(
        private api_urls: ApiEndpointsService,
        private quarter_s: QuarterService,
        private user_service: UserDataService,
        private user_cat_service: UserCategoriesDataService,
        private pricelist_data_service: PricelistsDataService,
        private messages_service: MessagesService
    )
    {

    }

    asis() { return 0; }

    ngOnInit(): void
    {
        this.user_data$ = this.user_service.current$;
        this.user_categories_data$ = this.user_cat_service.current$;
        this.pricelists_data$ = this.pricelist_data_service.current$;
        this.user_service.next_current(null);
        this.user_cat_service.next_current(null);
        this.pricelist_data_service.next_current(null);
        this.user_service.fetch_all();
        this.user_cat_service.fetch_all();
        this.pricelist_data_service.fetch_all();
        this.USERS_TABLE_META.api_url = this.api_urls.USERS_URL(this.quarter_s.selected_quarter);
        this.USERS_CATEGORIES_META.api_url = this.api_urls.USER_CATEGORIES_URL(this.quarter_s.selected_quarter);
        this.PRICELISTS_META.api_url = this.api_urls.PRICELISTS_URL(this.quarter_s.selected_quarter);

        const pricelists_valuemap$ = this.pricelists_data$.pipe(
            map(val => {
                if (val == null) return null;
                const result = { };
                val.map(pl => result[pl.pricelists_id] = pl.name);
                return result;
            })
        );

        const usercategs_valuemap$ = this.user_categories_data$.pipe(
            map(val => {
                if (val == null) return null;
                const result = { };
                val.map(cat => result[cat.user_categories_id] = cat.name);
                return result;
            })
        );

        this.USERS_CATEGORIES_META.render_columns.push(
            {
                display_name: "Pricelist",
                property_key: "fk_pricelists_id",
                editable: true,
                value_map$: pricelists_valuemap$,
                input_type: InputType.SELECT
            }
        );

        this.USERS_TABLE_META.render_columns.push({
            display_name: "Category",
            property_key: "fk_user_categories_id",
            editable: true,
            value_map$: usercategs_valuemap$,
            input_type: InputType.SELECT
        });

        this.merge_to_user_options$ = combineLatest(this.user_data$, this.merge_from_user$)
            .pipe(
                map(value => value[0].filter(ud => ud.users_id != value[1]))
            );

        this._merge_sub$ = this.merge_button_clicked$.pipe(
            withLatestFrom(this.merge_from_user$, this.merge_to_user$),
            filter(([_, from, to]) => !!from && !!to),
            tap(v => console.log(`Tapped ${v[0]} ${v[1]}`)),
            exhaustMap(([_, from, to]) => this.user_service.merge_users(from, to)),
            retry()
        ).subscribe(result => {
            this.messages_service.success(result);
            this.merge_to_user$.next("");
            this.merge_from_user$.next("")
            this.user_service.fetch_all();
        });
    }

    ngOnDestroy(): void {
        this._merge_sub$.unsubscribe()
    }



}
