<table class="table table-bordered table-sm tbl-realtime">

    <!-- Render table header -->
    <thead class="thead-dark">
        <!-- Table title -->
        <tr class="width-row">
            <th *ngFor="let m of metadata.render_columns"></th>
        </tr>
        <tr class="caption-row">
            <th [attr.colSpan]="metadata.render_columns.length">
                <div class="d-flex">
                    <div class="px-2 w-100">{{ metadata.table_caption }} </div>
                    <div class="ml-auto px-2 cursor-pointer" (click)="spawn_row()">
                        <i class="fas fa-plus"></i>
                    </div>
                </div>
            </th>
        </tr>

        <tr class="thead-row">
            <th *ngFor="let col of metadata.render_columns">{{ col.display_name }}</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngIf="data && data.length === 0"><td class="center" [attr.colSpan]="metadata.render_columns.length">No records available.</td></tr>
        <tr *ngIf="data === null || data === undefined">
            <td class="center" [attr.colSpan]="metadata.render_columns.length">
                <div class="w-100 flex-mid display-4 p-4"><i class="fas fa-circle-notch fa-spin"></i></div>
            </td>
        </tr>
        <ng-container *ngIf="data && data.length > 0">
            <tr lib-entity-table-row *ngFor="let row of data" [metadata]="metadata" [row_data]="row"></tr>
        </ng-container>
    </tbody>

</table>
