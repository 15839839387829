import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from '../../utils/local-storage/local-storage.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { QuarterService } from '../../services/quarter.service';

@Component({
    selector: 'app-facility',
    templateUrl: './facility.component.html',
    styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit, OnDestroy
{
    private _router_sub: Subscription;
    private LOCALS_ROUTE_KEY = "cf-accounting-facility-child-route";
    private DEFAULT_CHILDROUTE = "utilization";

    constructor(
        private router: Router,
        private aroute: ActivatedRoute,
        private local_s: LocalStorageService,
        private qt_service: QuarterService
    ) { }

    ngOnInit(): void
    {
        this._router_sub = this.router.events.pipe(
            filter((e) => e instanceof NavigationEnd)
        )
        .subscribe(() => {
            this.qt_service.select_facility(this.aroute.snapshot.paramMap.get("fac_id"));
            this.handle_redirection()
        });

        this.qt_service.select_facility(this.aroute.snapshot.paramMap.get("fac_id"));
        this.handle_redirection();
    }

    ngOnDestroy()
    {
        this._router_sub.unsubscribe();
    }

    handle_redirection()
    {
        let child_route = this.aroute.snapshot.firstChild;

        if (child_route !== null)
        {
            // There apparently is a child route, just save i t to the local storage an end
            this.local_s.save(this.LOCALS_ROUTE_KEY, child_route.url[0].path);
            return;
        }
        else
        {
            // No child route, lets redirect, use local storage
            let localval = this.local_s.retrieve<string>(this.LOCALS_ROUTE_KEY);
            let routename = localval ? localval : this.DEFAULT_CHILDROUTE;

            if (localval && this.aroute.snapshot.routeConfig.children.map((r) => r.path).indexOf(localval) === -1)
            {
                // Value in local storage is invalid, reset it
                this.local_s.save(this.LOCALS_ROUTE_KEY, null);
                routename = this.DEFAULT_CHILDROUTE;
            }

            // Perform navigation, finally
            console.log("Navigating to ", routename);
            this.router.navigate( [routename], { relativeTo: this.aroute } );
        }
    }
}
