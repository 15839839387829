import {HttpClient} from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import {RealtimeInputBase} from '../realtime_input_base';
import {Observable} from "rxjs";
import {KeyValue} from "@angular/common";

@Component({
    selector: 'util-realtime-select',
    templateUrl: './realtime-select.component.html',
    styleUrls: ['./realtime-select.component.css']
})
export class RealtimeSelectComponent extends RealtimeInputBase {

    @Input() value_map?: { [key: string]: string | number | Date };
    show_select: boolean = false;
    @Input() value_map$?: Observable<{ [key: string]: string | number | Date }>;

    constructor(
        http_c: HttpClient
    ) {
        super(http_c);
    }

    valueAscOrder = (a: KeyValue<string,string>, b: KeyValue<string,string>): number => {
        return a.value.localeCompare(b.value);
    }



}
