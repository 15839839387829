import { Component, Input, OnInit } from '@angular/core';
import { DataValue, EntityTableData, EntityTableMetadata, InputType } from '../entity_table_metadata';

@Component({
    selector: '[lib-entity-table-row]',
    templateUrl: './entity-table-row.component.html',
    styleUrls: ['./entity-table-row.component.css']
})
export class EntityTableRowComponent implements OnInit {
    
    @Input() row_data: EntityTableData;
    @Input() metadata: EntityTableMetadata;

    input_types = InputType;

    get entity_id(): DataValue { return this.get_data_value(this.metadata.id_property_name) };
    get entity_url(): string { return this.metadata.api_url + "/" + this.entity_id };

    constructor() { }
    
    ngOnInit(): void 
    {

    }

    onSelectChange(event)
    {

    }

    onInputInput(event)
    {

    }

    get_data_value(key: string)
    {
        let val = this.row_data[key];
        if (val === undefined || val === null) val = "";
        return val;
    }
    
}
