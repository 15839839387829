import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from './api-endpoints.service';
import { QuarterService } from './quarter.service';
import { CurrentSync } from '../utils/current_sync';
import {UserData} from "./user-data.service";

export interface UserCategoryData
{
    user_categories_id: string;
    name: string;
    alias: string;
    include_in_user_reports: boolean;

    fk_pricelists_id: string;
    users: UserData[];
}

@Injectable({
  providedIn: 'root'
})
export class UserCategoriesDataService extends CurrentSync<UserCategoryData[]> {

  constructor(
      private httpc: HttpClient,
      private urls: ApiEndpointsService,
      private qt: QuarterService
  )
  {
    super();
  }

  fetch_all(): void
  {
    this.httpc.get<UserCategoryData[]>(this.urls.USER_CATEGORIES_URL(this.qt.selected_quarter))
      .subscribe((v) => this.next_current(v));
  }
}
