import { HttpClient } from '@angular/common/http';
import {Component, Input, OnInit} from '@angular/core';
import { RealtimeInputBase } from '../realtime_input_base';

@Component({
  selector: 'util-realtime-numberinput',
  templateUrl: './realtime-numberinput.component.html',
  styleUrls: ['./realtime-numberinput.component.css']
})
export class RealtimeNumberinputComponent extends RealtimeInputBase {

    @Input() decimals: number = 2;

  constructor(
    http_c: HttpClient
  )
  {
      super(http_c);
  }

  ngOnInit(): void {
  }

  formatted_value() {
      if (typeof this.current_value == "number") {
          return this.current_value.toFixed(this.decimals);
      }

      return this.current_value;
  }

    get_step() {
        return Math.pow(10, -this.decimals).toFixed(this.decimals)
    }
}
