import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RealtimeInputBase } from '../realtime_input_base';

@Component({
    selector: 'util-realtime-textinput',
    templateUrl: './realtime-textinput.component.html',
    styleUrls: ['./realtime-textinput.component.css']
})
export class RealtimeTextinputComponent extends RealtimeInputBase 
{    
    constructor(
        http_c: HttpClient
    ) 
    {
        super(http_c); 
    }
}
    