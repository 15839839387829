import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RealtimeInputBase } from '../realtime_input_base';

@Component({
  selector: 'util-realtime-textarea',
  templateUrl: './realtime-textarea.component.html',
  styleUrls: ['./realtime-textarea.component.css']
})
export class RealtimeTextareaComponent extends RealtimeInputBase {

  constructor(
    http_c: HttpClient
  ) 
  {
      super(http_c);
  }

  keydown_enter(event: KeyboardEvent, divref: HTMLDivElement)
  {
    if (event.ctrlKey) { event.preventDefault(); divref.blur() }
  }
}
