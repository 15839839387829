
<table class="table table-bordered table-sm tbl-realtime">

    <thead class="thead-dark">
        <tr class="width-row"><th></th><th></th><th></th><th></th><th></th><th></th></tr>
        <tr class="caption-row">
            <th colspan="6">
                <div class="d-flex">
                    <div class="px-2 w-100">Projects</div>
                    <div class="ml-auto px-2 cursor-pointer" (click)="spawn_row()">
                        <i class="fas fa-plus"></i>
                    </div>
                </div>
            </th>
        </tr>

        <tr class="thead-row">
            <th>User</th>
            <th>Project name</th>
            <th>Project description</th>
            <th>Funds</th>
            <th>Funds+</th>
            <th></th>
        </tr>

    </thead>

    <tbody>
        <tr *ngFor="let p of projects$ | async; trackBy: identify_payment">
            <td><util-realtime-select [property_key]="'fk_users_id'"
                                      [patch_url]="patch_url(p)"
                                      [current_value]="p.fk_users_id"
                                      [value_map]="users$ | async | usersForSelect"></util-realtime-select></td>
            <td><util-realtime-textinput [property_key]="'project_name'" [patch_url]="patch_url(p)" [current_value]="p.project_name"></util-realtime-textinput></td>
            <td><util-realtime-textinput [property_key]="'project_description'" [patch_url]="patch_url(p)" [current_value]="p.project_description"></util-realtime-textinput></td>
            <td class="numbercell"><util-realtime-numberinput [property_key]="'funds'" [patch_url]="patch_url(p)" [current_value]="p.funds"></util-realtime-numberinput></td>
            <td class="numbercell"><util-realtime-numberinput [property_key]="'funds_plus'" [patch_url]="patch_url(p)" [current_value]="p.funds_plus"></util-realtime-numberinput></td>
            <td><div (click)="delete_project(p)" [attr.has-dependency]="(p.service_usages_count > 0)" class="d-flex align-items-center justify-content-center cursor-pointer">
                <i class="fas fa-trash"></i>
            </div></td>
        </tr>
    </tbody>
</table>
