import { Injectable } from '@angular/core';

@Injectable()
export class LocalStorageService
{
    constructor() { }

    save(key: string, data: any)
    {
        localStorage.setItem(key, JSON.stringify(data));
    }

    retrieve<T>(key: string): T | null
    {
        let item = localStorage.getItem(key);
        if (item === null) return null;

        let data = JSON.parse(item) as T;
        return data;
    }

    retrieve_and_clar<T>(key: string): T | null
    {
        let result = this.retrieve<T>(key);
        localStorage.removeItem(key);
        return result;
    }

    retrieve_arr<T>(key: string): T[]
    {
        let arr: any[] = this.retrieve(key);
        if (arr === null) arr = [ ];
        if (!Array.isArray(arr))
        throw new Error(`Local storage push: no array at key ${key}`);

        return arr;
    }

    push(key: string, value: any, allow_duplicates: boolean = false)
    {
        let arr: any[] = this.retrieve_arr(key);

        if (allow_duplicates === false)
        {
            if (arr.indexOf(value) === -1)
            arr.push(value);
        }
        else
        {
            arr.push(value);
        }

        this.save(key, arr);
    }

    remove_arr_items(key: string, value: any)
    {
        let arr: any[] = this.retrieve_arr(key);
        let index = arr.indexOf(value);

        while (index !== -1)
        {
            arr.splice(index, 1);
            index = arr.indexOf(value);
        }

        this.save(key, arr);
    }
}
