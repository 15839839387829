import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataValue } from '../entity-table/entity_table_metadata';

export enum RealtimeInputStatus
{
    UNCHANGED = "untouched",
    CHANGED = "changed",
    AWAITING_RESPONSE = "awaiting_response",
    FAILED = "failed"
}

@Component({ template: '' })
export abstract class RealtimeInputBase implements OnInit
{
    @Input() property_key: string;
    @Input() current_value: DataValue;
    @Input() patch_url: string;

    @Output() on_value_updated: EventEmitter<DataValue> = new EventEmitter<DataValue>();

    protected last_value: DataValue;

    realtime_input_status = RealtimeInputStatus;
    status: RealtimeInputStatus = RealtimeInputStatus.UNCHANGED;

    constructor(
        private http_c: HttpClient
    )
    {

    }

    ngOnInit()
    {
        this.last_value = this.current_value;

    }

    input_refresh(value: DataValue)
    {
        this.current_value = value;
        this.status = (this.current_value == this.last_value) ? RealtimeInputStatus.UNCHANGED : RealtimeInputStatus.CHANGED;
    }

    try_submit()
    {
        if (this.status == RealtimeInputStatus.CHANGED)
        {
            let body = { };
            body[this.property_key] = this.current_value;

            this.status = RealtimeInputStatus.AWAITING_RESPONSE;

            this.http_c.patch(this.patch_url, body).subscribe(
                (v) => {
                    this.status = RealtimeInputStatus.UNCHANGED;
                    this.last_value = this.current_value;
                    this.on_value_updated.emit(this.current_value);
                },
                (e) => this.status = RealtimeInputStatus.FAILED
            );
        }
    }
}
