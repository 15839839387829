import {Component, OnInit} from '@angular/core';
import {
    FacilityReportData,
    FacilityReportsService,
    FacilityReportStatus
} from "../../services/facility-reporting.service";
import {QuarterService} from "../../services/quarter.service";
import {environment} from "../../../environments/environment";
import {ApiEndpointsService} from "../../services/api-endpoints.service";
import {MessagesService} from "../../utils/messages/messages.service";
import {Observable} from "rxjs";

@Component({
    selector: 'app-facility-overview',
    templateUrl: './facility-overview.component.html',
    styleUrls: ['./facility-overview.component.scss']
})
export class FacilityOverviewComponent implements OnInit {

    facility_report_info$: Observable<FacilityReportData>;

    constructor(
        private facility_rep_service: FacilityReportsService,
        private qt: QuarterService,
        private urls: ApiEndpointsService,
        private messages: MessagesService
    ) {
        console.log("App fac component")
    }

    ngOnInit(): void {
        // Load facility report info
        this.facility_report_info$ = this.facility_rep_service.get_report_for_fac(this.qt.selected_facility)
    }

    get_iframe_url(reportid: string) {
        return environment.api_base +
            this.urls.FACILITY_REPORT_PREVIEW_URL(this.qt.selected_quarter, reportid);
    }

    is_sendable(fr: FacilityReportData)
    {
        return fr.status != FacilityReportStatus.Sent;
    }

    send_click(facility_report_info: FacilityReportData) {
        this.facility_rep_service.send_report(facility_report_info)
            .subscribe(
                (r) => {
                    this.messages.success(r);
                    this.facility_report_info$ = this.facility_rep_service.get_report_for_fac(this.qt.selected_facility)
                },
                (e) => {
                    this.messages.error("Failed to send the report.");
                    console.error(e)
                }
            );
    }
}
