<div class="container">
    <div class="row">

        <div class="d-flex align-items-center justify-content-center m-2">
            <a routerLink="/"><button class="btn btn-outline-secondary me-3">&lt; Back</button></a>

            <select class="form-control" (change)="qt_from$.next($event.target.value)">
                <option *ngFor="let q of (qt_list$ | async).reverse()"
                        [value]="q"
                >
                    {{ q | quarter }}
                </option>
            </select>
            &nbsp;-&nbsp;
            <select class="form-control" (change)="qt_to$.next($event.target.value)">
                <option *ngFor="let q of (qt_list$ | async)"
                        [value]="q"
                >
                    {{ q | quarter }}
                </option>
            </select>
        </div>


    </div>

    <div class="row">

        <ng-container *ngIf="qt_to$ | async as qt_to">
            <ng-container *ngIf="qt_from$ | async as qtfrom">

                <iframe [cachedSrc]="get_link(qtfrom, qt_to)"></iframe>

            </ng-container>
        </ng-container>
    </div>

</div>
