import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserData, UserDataService } from '../../services/user-data.service';
import {ProjectsService, ProjectData} from '../../services/projects.service';
import { QuarterService } from '../../services/quarter.service';


@Component({
  selector: 'app-payments',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy
{
  filter = '';

  users$: Observable<UserData[]>;
  projects$: Observable<ProjectData[]>;


  constructor(
    private projects_service: ProjectsService,
    private quarter_service: QuarterService,
    private user_service: UserDataService,
  ) { }

  ngOnDestroy(): void {
  }

  ngOnInit(): void
  {
    this.projects_service.load_projects(this.quarter_service.selected_facility);

    this.user_service.fetch_all();
    this.users$ = this.user_service.current$;

    this.projects$ = this.projects_service.current$;
  }

  spawn_row()
  {
    this.projects_service.new_project().subscribe(() => {
      console.log("New project, fetchin all.");
      this.projects_service.load_projects();
    });
  }


  identify_payment(index, item: ProjectData) { return item.projects_id };



  patch_url(payment: ProjectData)
  {
    return this.projects_service.get_patch_url(payment.projects_id);
  }

    delete_project(p: ProjectData) {
        this.projects_service.delete_project(p.projects_id)
            .subscribe(v => this.projects_service.load_projects());
    }
}
