import {Injectable} from '@angular/core';
import {CurrentSync} from '../utils/current_sync';
import {HttpClient} from '@angular/common/http';
import {ApiEndpointsService} from './api-endpoints.service';
import {QuarterService} from './quarter.service';
import {UserData} from './user-data.service';

export enum UserReportStatus {
    NotSentYet = "Not sent yet",
    Sent = "Sent",
    NoPaymentRequried = "No payment required",
    Approved = "Approved",
    Refused = "Refused",
    InvoiceLater = "Invoice later",
    Invoiced = "Invoiced",
    Resolved = "Resolved"
}

export enum PaymentMethod {
    Auto= "Auto",
    InternalInvoice = "Internal invoice",
    Barter = "Barter",
    ExternalInvoice = "External invoice",
    Prepayment = "Pre-payment"
}


export interface UserReportData {
    user_reports_id: string;

    status: UserReportStatus;
    dt_last_reminder: Date;
    dt_status_changed: Date;
    user_comments: string;
    admin_comments: string;
    user: UserData;

    // Additional client meta
    selected: boolean;

}

@Injectable({
    providedIn: 'root'
})
export class UserReportsService extends CurrentSync<UserReportData[]> {
    constructor(
        private httpc: HttpClient,
        private urls: ApiEndpointsService,
        private qt: QuarterService
    ) {
        super();
    }

    load_reports(quarter: string = this.qt.selected_quarter) {
        this.httpc.get<UserReportData[]>(this.urls.USER_REPORTS_URL(quarter))
            .subscribe(data => this.next_current(data));
    }

    send_report(report: UserReportData, quarter: string = this.qt.selected_quarter) {
        return this.httpc.post(this.urls.USER_REPORT_SEND_URL(quarter, report.user_reports_id), {}, {responseType: "text"});
    }

    invoice_now(r: UserReportData, quarter: string = this.qt.selected_quarter) {
        return this.httpc.post(this.urls.USER_REPORT_INVOICE_NOW_URL(quarter, r.user_reports_id), {}, { responseType: "text" });
    }

    generate_crm_order(r: UserReportData, quarter: string = this.qt.selected_quarter) {
        return this.httpc.post(this.urls.USER_REPORT_GENERATE_ORDER_URL(quarter, r.user_reports_id), {}, {responseType: "text"});
    }

    resolve(r: UserReportData, quarter: string = this.qt.selected_quarter) {
        return this.httpc.post(this.urls.USER_REPORT_RESOLVE_URL(quarter, r.user_reports_id), {}, { responseType: "text" });
    }
}


