

<table class="table table-bordered table-sm tbl-realtime">

    <thead class="thead-dark">
        <tr class="width-row">
            <th></th><th></th><th></th><th></th><th></th><th></th><th></th>
        </tr>
        <tr class="caption-row">
            <th colspan="7">
                <div class="d-flex">
                    <div class="px-2 w-100">Service utilization</div>
                    <div class="ml-auto px-2 cursor-pointer" (click)="spawn_row()">
                        <i class="fas fa-plus"></i>
                    </div>
                </div>
            </th>
        </tr>

        <tr class="thead-row">
            <th>Service</th>
            <th>User / Project</th>
            <th>Units used</th>
            <th>Custom discount</th>
            <th>Comments for user</th>
            <th>Comments for CF</th>
            <th></th>
            <!-- <th><input type="text" (input)="filter_change($event.target.value)" /></th> -->
        </tr>

    </thead>

    <tbody>
        <tr *ngFor="let u of utilization$ | async; trackBy: identify_util">

            <td><util-realtime-select [property_key]="'fk_services_id'"
                [patch_url]="patch_url(u)"
                [current_value]="u.fk_services_id"
                [value_map]="services$ | async | servicesForSelect"></util-realtime-select>
            </td>

            <td><util-realtime-select [property_key]="'fk_projects_id'"
                [patch_url]="patch_url(u)"
                [current_value]="u.fk_projects_id"
                [value_map]="projects$ | async | userProjectsForSelect"></util-realtime-select>
            </td>

            <td class="numbercell">
                <util-realtime-numberinput [property_key]="'units_used'"
                    [patch_url]="patch_url(u)"
                    [current_value]="u.units_used"></util-realtime-numberinput>
            </td>

            <td>
                <util-realtime-textinput [property_key]="'discount_expression'"
                    [patch_url]="patch_url(u)"
                    [current_value]="u.discount_expression"></util-realtime-textinput>
            </td>

            <td>
                <util-realtime-textinput [property_key]="'comments_for_user'"
                    [patch_url]="patch_url(u)"
                    [current_value]="u.comments_for_user"></util-realtime-textinput>
            </td>

            <td>
                <util-realtime-textinput [property_key]="'comments_for_cf'"
                                         [patch_url]="patch_url(u)"
                                         [current_value]="u.comments_for_cf"></util-realtime-textinput>
            </td>

            <td>
                <div (click)="delete_util(u)" class="d-flex align-items-center justify-content-center cursor-pointer">
                    <i class="fas fa-trash"></i>
                </div>
            </td>
        </tr>
    </tbody>
</table>
