import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiEndpointsService } from './api-endpoints.service';
import { QuarterService } from './quarter.service';
import { CurrentSync } from '../utils/current_sync';

export interface QuarterStatistics
{
    total_users: number;
    total_user_categories: number;
    unresolved_user_reports: number;
    pending_user_reports: number;

    total_facilities: number;
    total_services: number;
    unresolved_facility_reports: number;
    pending_facility_reports: number;
}

@Injectable({
  providedIn: 'root'
})
export class StatsService extends CurrentSync<QuarterStatistics>
{
  constructor(
    private qt: QuarterService,
    private httpc: HttpClient,
    private urls: ApiEndpointsService
  ) { super(); }

  load_statistics(quarter: string = this.qt.selected_quarter)
  {
    this.httpc.get<QuarterStatistics>(this.urls.QT_STATISTICS(quarter))
      .subscribe((stats) => this.next_current(stats));
  }

}
