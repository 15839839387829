import { Component, OnInit } from '@angular/core';
import { QuarterService } from '../../services/quarter.service';
import { StatsComponent } from "../stats/stats.component";
import {UtilImportResultData} from "../toolbox/toolbox.component";

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

    util_import_result: UtilImportResultData;

  constructor(
    private qt: QuarterService
  ) { }

  ngOnInit(): void {
  }

  refresh_quarter()
  {
    this.qt.refresh_quarter();
  }

    refresh_utilimport_result($event: UtilImportResultData) {
        this.util_import_result = $event;
    }
}
