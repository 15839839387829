import {Component, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiEndpointsService} from 'src/app/services/api-endpoints.service';
import {UserData, UserDataService} from 'src/app/services/user-data.service';
import {QuarterService} from 'src/app/services/quarter.service';
import {PricingData, ServiceData, ServicesService} from 'src/app/services/services.service';
import {ServiceUsageData, UtilizationService} from "../../services/utilization.service";
import {} from "../../services/services.service";
import {UserCategoriesDataService, UserCategoryData} from "../../services/user-categories-data.service";
import {ProjectData, ProjectsService} from "../../services/projects.service";

@Component({
    selector: 'app-utilization',
    templateUrl: './utilization.component.html',
    styleUrls: ['./utilization.component.scss']
})
export class UtilizationComponent implements OnInit, OnDestroy {
    utilization$: Observable<ServiceUsageData[]>;
    users$: Observable<UserData[]>;
    services$: Observable<ServiceData[]>;
    user_categories$: Observable<UserCategoryData[]>;
    projects$: Observable<ProjectData[]>;

    constructor(
        private quarter_service: QuarterService,
        private service_util_service: UtilizationService,
        private urls: ApiEndpointsService,
        private user_service: UserDataService,
        private services_service: ServicesService,
        private user_category_data_service: UserCategoriesDataService,
        private projects_service: ProjectsService
    ) {
    }

    ngOnInit(): void {
        this.users$ = this.user_service.current$;
        this.user_service.fetch_all();

        this.utilization$ = this.service_util_service.current$;
        this.service_util_service.load_utilizations();

        this.services$ = this.services_service.current$;
        this.services_service.load_services_light();

        this.user_categories$ = this.user_category_data_service.current$;
        this.user_category_data_service.fetch_all();

        this.projects$ = this.projects_service.current$;
        this.projects_service.load_projects();
    }

    spawn_row() {
        this.service_util_service.new_utilization().subscribe(result => {
            console.log("Utilization created.");
            this.service_util_service.load_utilizations();
        });
    }

    identify_util(index, item: ServiceUsageData) {
        return item.services_usages_id
    };

    identify_user(index, item: UserData) {
        return item.users_id
    };

    identify_pricing(index, item: PricingData) {
        return item.pricing_id;
    };

    patch_url(item: ServiceUsageData) {
        return this.service_util_service.get_patch_url(item.services_usages_id);
    }

    ngOnDestroy(): void {
        this.services_service.next_current(null);
    }


    delete_util(u: ServiceUsageData) {
        this.service_util_service.delete_util(u.services_usages_id)
            .subscribe(v => this.service_util_service.load_utilizations());

    }
}
