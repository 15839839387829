
<div *ngIf="facility_report_info$ | async as facility_report" class="facility-overview-page">

    <div class="report-preview-container">
        <iframe [cachedSrc]="get_iframe_url(facility_report.facility_reports_id)"></iframe>
    </div>

    <div class="report-details-container">
        <div class="facility-report-strip">
            <div><strong>Facility report:</strong> {{ facility_report.status }}</div>
            <button class="btn btn-ceitec btn-sm" *ngIf="is_sendable(facility_report)" (click)="send_click(facility_report)">Send</button>
        </div>

        <div *ngIf="facility_report.cfhead_comments" class="report-user-commentary">
            <strong>CF head commentary: </strong>
            <p style="white-space: pre-line">{{ facility_report.cfhead_comments }}</p>
        </div>
    </div>

</div>
